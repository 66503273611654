<template>
  <svg
    viewBox="0 0 1500 1600"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="log-in-girl-7-ellipse1-fill"
        x1="-66.270006"
        y1="0.000022"
        x2="66.279994"
        y2="0.000022"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse1-fill-0"
          offset="0"
          stop-color="rgb(255,80,83)"
        />
        <stop
          id="log-in-girl-7-ellipse1-fill-1"
          offset="1"
          stop-color="rgb(240,255,187)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse2-fill"
        x1="-31.780006"
        y1="0.000022"
        x2="31.789994"
        y2="0.000022"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse2-fill-0"
          offset="0"
          stop-color="rgb(255,80,83)"
        />
        <stop
          id="log-in-girl-7-ellipse2-fill-1"
          offset="1"
          stop-color="rgb(240,255,187)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse3-fill"
        x1="-10.779951"
        y1="0.000002"
        x2="10.770049"
        y2="0.000002"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse3-fill-0"
          offset="0"
          stop-color="rgb(255,80,83)"
        />
        <stop
          id="log-in-girl-7-ellipse3-fill-1"
          offset="1"
          stop-color="rgb(240,255,187)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse4-fill"
        x1="-15.539985"
        y1="0.000010"
        x2="15.540015"
        y2="0.000010"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse4-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-ellipse4-fill-1"
          offset="1"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse5-fill"
        x1="-15.539946"
        y1="-0.000020"
        x2="15.540054"
        y2="-0.000020"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse5-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-ellipse5-fill-1"
          offset="1"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse6-fill"
        x1="-15.540002"
        y1="-0.000024"
        x2="15.539998"
        y2="-0.000024"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse6-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-ellipse6-fill-1"
          offset="1"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse7-fill"
        x1="-61.430029"
        y1="0"
        x2="61.429971"
        y2="0"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse7-fill-0"
          offset="0"
          stop-color="rgb(255,218,224)"
        />
        <stop
          id="log-in-girl-7-ellipse7-fill-1"
          offset="1"
          stop-color="rgb(254,255,246)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse8-fill"
        x1="-31.780029"
        y1="0"
        x2="31.789971"
        y2="0"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse8-fill-0"
          offset="0"
          stop-color="rgb(255,218,224)"
        />
        <stop
          id="log-in-girl-7-ellipse8-fill-1"
          offset="1"
          stop-color="rgb(254,255,246)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse9-fill"
        x1="-145.679990"
        y1="-0.000005"
        x2="145.670010"
        y2="-0.000005"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse9-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-ellipse9-fill-1"
          offset="1"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-ellipse10-fill"
        x1="-73.739990"
        y1="-0.000005"
        x2="73.730010"
        y2="-0.000005"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-ellipse10-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-ellipse10-fill-1"
          offset="1"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path8-fill"
        x1="1020.783663"
        y1="1389.596575"
        x2="1063.258673"
        y2="1326.766812"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path8-fill-0"
          offset="0"
          stop-color="rgb(156,179,239)"
        />
        <stop
          id="log-in-girl-7-path8-fill-1"
          offset="1"
          stop-color="rgb(186,211,246)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-polygon1-fill"
        x1="981.540000"
        y1="1324.270000"
        x2="1095.230000"
        y2="1324.270000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-polygon1-fill-0"
          offset="0"
          stop-color="rgb(13,18,30)"
        />
        <stop
          id="log-in-girl-7-polygon1-fill-1"
          offset="0.78"
          stop-color="rgb(44,82,170)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path9-fill"
        x1="828.870000"
        y1="1133.300000"
        x2="1109.910000"
        y2="931.860000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path9-fill-0"
          offset="0.51"
          stop-color="rgb(3,10,22)"
        />
        <stop
          id="log-in-girl-7-path9-fill-1"
          offset="1"
          stop-color="rgb(92,79,144)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path10-fill"
        x1="985.440000"
        y1="1040.640000"
        x2="1030.830000"
        y2="998.150000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path10-fill-0"
          offset="0"
          stop-color="rgb(13,18,30)"
        />
        <stop
          id="log-in-girl-7-path10-fill-1"
          offset="0.78"
          stop-color="rgb(44,82,170)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path11-fill"
        x1="693.250000"
        y1="942.200000"
        x2="980.650000"
        y2="736.210000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path11-fill-0"
          offset="0.51"
          stop-color="rgb(3,10,22)"
        />
        <stop
          id="log-in-girl-7-path11-fill-1"
          offset="1"
          stop-color="rgb(92,79,144)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path12-fill"
        x1="501.750000"
        y1="933.830000"
        x2="1052.330000"
        y2="906.930000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path12-fill-0"
          offset="0"
          stop-color="rgb(11,25,52)"
        />
        <stop
          id="log-in-girl-7-path12-fill-1"
          offset="0.51"
          stop-color="rgb(3,10,22)"
        />
        <stop
          id="log-in-girl-7-path12-fill-2"
          offset="1"
          stop-color="rgb(92,79,144)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-polygon4-fill"
        x1="617.900000"
        y1="1390.070000"
        x2="678.920000"
        y2="1390.070000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-polygon4-fill-0"
          offset="0"
          stop-color="rgb(13,18,30)"
        />
        <stop
          id="log-in-girl-7-polygon4-fill-1"
          offset="0.78"
          stop-color="rgb(44,82,170)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path14-fill"
        x1="598.149136"
        y1="1417.485820"
        x2="673.229715"
        y2="1409.634277"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path14-fill-0"
          offset="0"
          stop-color="rgb(156,179,239)"
        />
        <stop
          id="log-in-girl-7-path14-fill-1"
          offset="1"
          stop-color="rgb(186,211,246)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path15-fill"
        x1="510.440000"
        y1="1134.530000"
        x2="1062.020000"
        y2="1107.570000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path15-fill-0"
          offset="0"
          stop-color="rgb(11,25,52)"
        />
        <stop
          id="log-in-girl-7-path15-fill-1"
          offset="0.51"
          stop-color="rgb(3,10,22)"
        />
        <stop
          id="log-in-girl-7-path15-fill-2"
          offset="1"
          stop-color="rgb(92,79,144)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path16-fill"
        x1="799.500000"
        y1="1183.430000"
        x2="930.620000"
        y2="1106.340000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path16-fill-0"
          offset="0"
          stop-color="rgb(13,18,30)"
        />
        <stop
          id="log-in-girl-7-path16-fill-1"
          offset="0.78"
          stop-color="rgb(44,82,170)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path18-fill"
        x1="729.308934"
        y1="689.976193"
        x2="909.218934"
        y2="603.619389"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path18-fill-0"
          offset="0"
          stop-color="rgb(223,94,85)"
        />
        <stop
          id="log-in-girl-7-path18-fill-1"
          offset="1"
          stop-color="rgb(243,172,154)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path19-fill"
        x1="797.667834"
        y1="673.883098"
        x2="826.238094"
        y2="592.139874"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path19-fill-0"
          offset="0"
          stop-color="rgb(8,18,33)"
        />
        <stop
          id="log-in-girl-7-path19-fill-1"
          offset="0.51"
          stop-color="rgb(56,44,44)"
        />
        <stop
          id="log-in-girl-7-path19-fill-2"
          offset="1"
          stop-color="rgb(88,70,127)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path20-fill"
        x1="975.140000"
        y1="456.380000"
        x2="885.050000"
        y2="644.550000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path20-fill-0"
          offset="0"
          stop-color="rgb(243,172,154)"
        />
        <stop
          id="log-in-girl-7-path20-fill-1"
          offset="0.85"
          stop-color="rgb(238,153,136)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path23-fill"
        x1="601.196351"
        y1="723.655042"
        x2="806.336348"
        y2="723.619239"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path23-fill-0"
          offset="0"
          stop-color="rgb(56,44,44)"
        />
        <stop
          id="log-in-girl-7-path23-fill-1"
          offset="0.96"
          stop-color="rgb(96,76,148)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path24-fill"
        x1="631.520000"
        y1="749.670000"
        x2="708.910000"
        y2="705"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path24-fill-0"
          offset="0"
          stop-color="rgb(45,110,245)"
        />
        <stop
          id="log-in-girl-7-path24-fill-1"
          offset="0.49"
          stop-color="rgb(10,18,32)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path25-fill"
        x1="720.080000"
        y1="716.140000"
        x2="773.530000"
        y2="716.140000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path25-fill-0"
          offset="0"
          stop-color="rgb(8,18,33)"
        />
        <stop
          id="log-in-girl-7-path25-fill-1"
          offset="1"
          stop-color="rgb(56,44,44)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path26-fill"
        x1="642.120000"
        y1="639.970000"
        x2="744.810000"
        y2="639.970000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path26-fill-0"
          offset="0"
          stop-color="rgb(8,18,33)"
        />
        <stop
          id="log-in-girl-7-path26-fill-1"
          offset="1"
          stop-color="rgb(56,44,44)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path27-fill"
        x1="642.120000"
        y1="639.970000"
        x2="744.810000"
        y2="639.970000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path27-fill-0"
          offset="0"
          stop-color="rgb(8,18,33)"
        />
        <stop
          id="log-in-girl-7-path27-fill-1"
          offset="1"
          stop-color="rgb(56,44,44)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path28-fill"
        x1="541.102217"
        y1="723.665792"
        x2="654.398220"
        y2="748.193793"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path28-fill-0"
          offset="0"
          stop-color="rgb(243,172,154)"
        />
        <stop
          id="log-in-girl-7-path28-fill-1"
          offset="1"
          stop-color="rgb(223,94,85)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path29-fill"
        x1="596.164821"
        y1="657.595919"
        x2="655.510803"
        y2="691.865562"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path29-fill-0"
          offset="0.04"
          stop-color="rgb(92,79,144)"
        />
        <stop
          id="log-in-girl-7-path29-fill-1"
          offset="0.61"
          stop-color="rgb(11,18,31)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path34-fill"
        x1="654.580000"
        y1="729.280000"
        x2="727.230000"
        y2="656.630000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path34-fill-0"
          offset="0.18"
          stop-color="rgb(243,172,154)"
        />
        <stop
          id="log-in-girl-7-path34-fill-1"
          offset="1"
          stop-color="rgb(223,94,85)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path36-fill"
        x1="674.980000"
        y1="615.670000"
        x2="727.460000"
        y2="615.670000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path36-fill-0"
          offset="0"
          stop-color="rgb(56,44,44)"
        />
        <stop
          id="log-in-girl-7-path36-fill-1"
          offset="0.96"
          stop-color="rgb(96,76,148)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path37-fill"
        x1="665.100000"
        y1="533.460000"
        x2="769.110000"
        y2="533.460000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path37-fill-0"
          offset="0"
          stop-color="rgb(223,94,85)"
        />
        <stop
          id="log-in-girl-7-path37-fill-1"
          offset="1"
          stop-color="rgb(243,172,154)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path38-fill"
        x1="665.100000"
        y1="533.460000"
        x2="769.110000"
        y2="533.460000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path38-fill-0"
          offset="0"
          stop-color="rgb(223,94,85)"
        />
        <stop
          id="log-in-girl-7-path38-fill-1"
          offset="1"
          stop-color="rgb(243,172,154)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path41-fill"
        x1="742.790000"
        y1="526.740000"
        x2="766.660000"
        y2="521.670000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path41-fill-0"
          offset="0"
          stop-color="rgb(243,172,154)"
        />
        <stop
          id="log-in-girl-7-path41-fill-1"
          offset="1"
          stop-color="rgb(253,243,196)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path42-fill"
        x1="-0.018133"
        y1="1.133650"
        x2="0.498251"
        y2="0.375192"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
      >
        <stop
          id="log-in-girl-7-path42-fill-0"
          offset="0"
          stop-color="rgb(12,6,16)"
        />
        <stop
          id="log-in-girl-7-path42-fill-1"
          offset="1"
          stop-color="rgb(87,61,61)"
        />
      </linearGradient>
      <radialGradient
        id="log-in-girl-7-path43-fill"
        cx="0"
        cy="0"
        r="0.803193"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="matrix(1 0 0 1.75321996083133 0.47074816120464 0.04133672267095)"
      >
        <stop
          id="log-in-girl-7-path43-fill-0"
          offset="0"
          stop-color="rgb(0,9,19)"
        />
        <stop
          id="log-in-girl-7-path43-fill-1"
          offset="1"
          stop-color="rgb(42,103,227)"
        />
      </radialGradient>
      <radialGradient
        id="log-in-girl-7-path45-fill"
        cx="0"
        cy="0"
        r="0.803193"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="matrix(1 0 0 1.75321996083133 0.47074816120464 0.04133672267095)"
      >
        <stop
          id="log-in-girl-7-path45-fill-0"
          offset="0"
          stop-color="rgb(255,255,255)"
        />
        <stop
          id="log-in-girl-7-path45-fill-1"
          offset="1"
          stop-color="rgb(42,103,227)"
        />
      </radialGradient>
      <radialGradient
        id="log-in-girl-7-path44-fill"
        cx="0"
        cy="0"
        r="1.106010"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="matrix(1 0 0 1.12231425593327 0.34204901002766 0.03020035480589)"
      >
        <stop
          id="log-in-girl-7-path44-fill-0"
          offset="0"
          stop-color="rgb(58,136,212)"
        />
        <stop
          id="log-in-girl-7-path44-fill-1"
          offset="1"
          stop-color="rgb(22,74,162)"
        />
      </radialGradient>
      <linearGradient
        id="log-in-girl-7-path46-fill"
        x1="731.130000"
        y1="554.430000"
        x2="767"
        y2="554.430000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path46-fill-0"
          offset="0"
          stop-color="rgb(223,94,85)"
        />
        <stop
          id="log-in-girl-7-path46-fill-1"
          offset="1"
          stop-color="rgb(243,172,154)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path47-fill"
        x1="739.500000"
        y1="563.460000"
        x2="762.060000"
        y2="563.460000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path47-fill-0"
          offset="0"
          stop-color="rgb(223,94,85)"
        />
        <stop
          id="log-in-girl-7-path47-fill-1"
          offset="1"
          stop-color="rgb(243,172,154)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path48-fill"
        x1="529.380000"
        y1="594.990000"
        x2="737.280000"
        y2="454.760000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path48-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-path48-fill-1"
          offset="1"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path49-fill"
        x1="524.860000"
        y1="586.430000"
        x2="719.950000"
        y2="454.840000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path49-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-path49-fill-1"
          offset="1"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path50-fill"
        x1="-0.080123"
        y1="0.936992"
        x2="1.161893"
        y2="0.130326"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
      >
        <stop
          id="log-in-girl-7-path50-fill-0"
          offset="0"
          stop-color="rgb(159,94,192)"
        />
        <stop
          id="log-in-girl-7-path50-fill-1"
          offset="1"
          stop-color="rgb(196,153,213)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path51-fill"
        x1="498.640000"
        y1="615.220000"
        x2="737.040000"
        y2="454.410000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path51-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-path51-fill-1"
          offset="0.59"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <linearGradient
        id="log-in-girl-7-path52-fill"
        x1="527.400000"
        y1="629.710000"
        x2="759.740000"
        y2="473"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="log-in-girl-7-path52-fill-0"
          offset="0"
          stop-color="rgb(200,142,218)"
        />
        <stop
          id="log-in-girl-7-path52-fill-1"
          offset="0.59"
          stop-color="rgb(238,237,237)"
        />
      </linearGradient>
      <radialGradient
        id="log-in-girl-7-belt-fill"
        cx="0"
        cy="0"
        r="45.780000"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.95000004768372 0 0 1 735.85703347682954 835.13000000000000)"
      >
        <stop
          id="log-in-girl-7-belt-fill-0"
          offset="0"
          stop-color="rgb(56,13,120)"
        />
        <stop
          id="log-in-girl-7-belt-fill-1"
          offset="1"
          stop-color="rgb(8,18,33)"
        />
      </radialGradient>
    </defs>
    <g id="log-in-girl-7-stars">
      <g
        id="log-in-girl-7-path1_tr"
        transform="translate(721.694252,768.442875) rotate(0)"
      >
        <path
          id="log-in-girl-7-path1"
          d="M411.511764,1447.247475C292.389485,1391.333241,212.035616,1273.913349,279.868192,1124.212305C380.009395,903.192656,750.082090,953.617976,773.871576,1173.295684C792.133819,1341.970291,532.382555,1407.203565,375.084418,1275.888985C194.667057,1125.311952,190.412343,597.202008,602.187065,572.842040C805.675077,560.811160,882.920479,849.263376,733.471220,940.739064C625.442282,1006.904241,488.796021,981.845345,375.084418,919.118893C193.248819,818.827395,170.119883,557.064906,286.376544,377.114262C457.157657,112.872909,811.493739,105.464273,934.822162,290.698813C1116.628618,563.746658,676.508565,771.337572,544.874707,521.410263C311.457986,78.215403,1155.319317,-144.220741,1262.270005,410.513698C1331.171116,767.908165,941.000240,944.615784,875.868145,776.211429C795.154860,567.520868,1223.627877,533.059062,1318.766391,862.431179C1388.765179,1104.847342,1071.254716,1282.393674,967.315355,1104.847342C854.478007,912.101657,1232.992133,774.440812,1326.450132,1051.197634C1393.544447,1249.888866,1147.956136,1442.876846,846.463991,1390.410656C320.763959,1298.990883,253.553077,708.676353,538.725771,724.173915C878.656051,742.653570,510.691285,1723.174903,149.701141,1343.722270C-5.867912,1180.182454,85.841575,865.590333,224.644335,696.915726"
          transform="translate(-721.694252,-768.442875)"
          fill="none"
          stroke="rgb(68,73,148)"
          stroke-width="3"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-dasharray="0,296.909637"
        />
      </g>
      <g
        id="log-in-girl-7-path2_tr"
        transform="translate(764.614975,792.540727) rotate(0)"
      >
        <path
          id="log-in-girl-7-path2"
          d="M225.077552,209.009347C314.805391,106.704936,454.832110,61.060283,578.170247,175.740377C755.420857,340.528945,592.381769,678.968487,377.751845,633.146772C212.925389,597.995357,232.479589,329.085165,405.388059,219.530542C603.756815,93.872619,1037.118242,577.757250,1037.118242,577.757250C1037.118242,577.757250,649.383280,838.584988,609.711472,667.049436C581.035865,543.040983,647.285065,420.094901,741.928168,330.846464C893.271649,188.134699,1147.417957,247.636764,1280.878040,415.071939C1476.837734,660.936146,1373.277218,1002.320505,1160.075246,1062.540135C845.780103,1151.313301,787.282642,665.390647,1064.111045,617.416234C1554.996248,532.324088,1501.530618,1409.236346,944.872199,1338.495520C586.252261,1292.925419,541.296059,864.426684,720.479746,854.641693C942.531135,842.526942,841.340824,1263.300194,500.944273,1251.558205C250.450413,1242.910136,182.035000,883.707775,381.948276,839.610083C598.967833,791.766136,610.721724,1196.929998,320.497771,1200.014599C112.143065,1202.232531,6.727182,907.005373,150.260640,634.889432C400.550507,160.345325,978.365929,280.188167,874.776271,548.222369C751.272996,867.781538,-58.880897,209.726913,411.721560,-17.312836C615.384424,-115.563465,884.937072,72.336320,999.892062,258.530720"
          transform="translate(-761.388901,-653.281860)"
          fill="none"
          stroke="rgb(79,32,138)"
          stroke-width="8"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-dasharray="0,296.964172"
        />
      </g>
      <g
        id="log-in-girl-7-ellipse1_ts"
        transform="translate(295.100006,872.539978) scale(0.733169,0.733169)"
      >
        <ellipse
          id="log-in-girl-7-ellipse1"
          rx="66.269997"
          ry="69.580002"
          transform="translate(0,0)"
          opacity="0.20000000298023224"
          fill="url(#log-in-girl-7-ellipse1-fill)"
          stroke="none"
          stroke-width="1"
        />
      </g>
      <g
        id="log-in-girl-7-ellipse2_ts"
        transform="translate(295.100006,872.539978) scale(0.733169,0.733169)"
      >
        <ellipse
          id="log-in-girl-7-ellipse2"
          rx="31.780001"
          ry="33.369999"
          transform="translate(0,0)"
          fill="url(#log-in-girl-7-ellipse2-fill)"
          stroke="none"
          stroke-width="1"
        />
      </g>
      <g
        id="log-in-girl-7-ellipse3_ts"
        transform="translate(1269.699951,247.059998) scale(0.576432,0.576432)"
      >
        <ellipse
          id="log-in-girl-7-ellipse3"
          rx="10.780000"
          ry="11.320000"
          transform="translate(0,0)"
          fill="url(#log-in-girl-7-ellipse3-fill)"
          stroke="none"
          stroke-width="1"
        />
      </g>
      <g
        id="log-in-girl-7-ellipse4_ts"
        transform="translate(446.983927,963.743720) scale(0.576432,0.576432)"
      >
        <ellipse
          id="log-in-girl-7-ellipse4"
          rx="15.540000"
          ry="16.320000"
          transform="translate(0.000015,0)"
          fill="url(#log-in-girl-7-ellipse4-fill)"
          stroke="none"
          stroke-width="1"
        />
      </g>
      <g
        id="log-in-girl-7-ellipse5_ts"
        transform="translate(1232.819885,582.520020) scale(0.576432,0.576432)"
      >
        <ellipse
          id="log-in-girl-7-ellipse5"
          rx="15.540000"
          ry="16.320000"
          transform="translate(0.000061,0)"
          fill="url(#log-in-girl-7-ellipse5-fill)"
          stroke="none"
          stroke-width="1"
        />
      </g>
      <g
        id="log-in-girl-7-ellipse6_ts"
        transform="translate(120.150002,700.400024) scale(0.576432,0.576432)"
      >
        <ellipse
          id="log-in-girl-7-ellipse6"
          rx="15.540000"
          ry="16.320000"
          transform="translate(0,0)"
          fill="url(#log-in-girl-7-ellipse6-fill)"
          stroke="none"
          stroke-width="1"
        />
      </g>
      <g
        id="log-in-girl-7-star"
        transform="matrix(1.25534968651613 0 0 1.25534968651613 -212.99661129883816 -375.80053802605357)"
      >
        <g
          id="log-in-girl-7-ellipse7_ts"
          transform="translate(1217.279968,878.750000) scale(0.371945,0.371945)"
        >
          <ellipse
            id="log-in-girl-7-ellipse7"
            rx="61.430000"
            ry="64.489998"
            transform="translate(0.000061,0)"
            opacity="0.20000000298023224"
            fill="url(#log-in-girl-7-ellipse7-fill)"
            stroke="none"
            stroke-width="1"
          />
        </g>
        <g
          id="log-in-girl-7-ellipse8_ts"
          transform="translate(1217.280029,878.750000) scale(0.452927,0.452927)"
        >
          <ellipse
            id="log-in-girl-7-ellipse8"
            rx="31.780001"
            ry="33.369999"
            transform="translate(0,0)"
            fill="url(#log-in-girl-7-ellipse8-fill)"
            stroke="none"
            stroke-width="1"
          />
        </g>
      </g>
      <g
        id="log-in-girl-7-star2"
        transform="matrix(1 0 0 1 -151.41200200000003 -11.64707700000002)"
      >
        <g
          id="log-in-girl-7-ellipse9_ts"
          transform="translate(527.990021,235.740005) scale(0.579296,0.579296)"
        >
          <ellipse
            id="log-in-girl-7-ellipse9"
            rx="145.669998"
            ry="152.940002"
            transform="translate(-0.000031,0)"
            opacity="0.20000000298023224"
            fill="url(#log-in-girl-7-ellipse9-fill)"
            stroke="none"
            stroke-width="1"
          />
        </g>
        <g
          id="log-in-girl-7-ellipse10_ts"
          transform="translate(527.989990,235.740013) scale(0.733169,0.733169)"
        >
          <ellipse
            id="log-in-girl-7-ellipse10"
            rx="73.739998"
            ry="77.410004"
            transform="translate(0,-0.000008)"
            fill="url(#log-in-girl-7-ellipse10-fill)"
            stroke="none"
            stroke-width="1"
          />
        </g>
      </g>
    </g>
    <g id="log-in-girl-7-stroke-3">
      <path
        id="log-in-girl-7-path3"
        d="M1500,573.299570C1405.072895,277.849390,984.064811,151.276513,837.043922,353.138468C712.341947,524.356209,834.220107,719.697883,921.150411,793.460672C1001.894978,861.974691,1161.047872,895.251504,1219.185240,806.463874C1277.322608,717.676244,1206.082925,568.799896,1060.952454,503.253405C841.048473,403.936327,408.279640,518.110168,275.509426,748.933707C206.653791,868.640536,241.510148,1029.908525,384.353117,1092.780822C659.496635,1213.885172,915.060323,889.747926,1205.628239,1068.043619C1423.636973,1201.816194,1369.043469,1471.067160,1304.577049,1600"
        fill="none"
        stroke="rgb(16,65,165)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dashoffset="5972"
        stroke-dasharray="600,4772"
      />
      <path
        id="log-in-girl-7-path4"
        d="M1500,573.299570C1405.072895,277.849390,984.064811,151.276513,837.043922,353.138468C712.341947,524.356209,834.220107,719.697883,921.150411,793.460672C1001.894978,861.974691,1161.047872,895.251504,1219.185240,806.463874C1277.322608,717.676244,1206.082925,568.799896,1060.952454,503.253405C841.048473,403.936327,408.279640,518.110168,275.509426,748.933707C206.653791,868.640536,241.510148,1029.908525,384.353117,1092.780822C659.496635,1213.885172,915.060323,889.747926,1205.628239,1068.043619C1423.636973,1201.816194,1369.043469,1471.067160,1304.577049,1600"
        fill="none"
        stroke="rgb(54,120,255)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dashoffset="5172"
        stroke-dasharray="200,4772"
      />
    </g>
    <g
      id="log-in-girl-7-stroke"
      transform="matrix(1.026422, 0.158284, -0.136814, 0.887199, 74.015945, 29.168991)"
    >
      <path
        id="log-in-girl-7-path5"
        d="M330.384299,475.692916C360.597872,370.181531,811.544089,101.496841,941.231358,321.799623C1070.918627,542.102404,388.520606,1029.847144,313.110408,844.125227C244.882256,676.091413,969.771763,455.678431,1121.393993,768.191802C1273.016222,1080.705173,920.482358,1466.436082,538.241590,1317.786895"
        transform="matrix(0.99643925809352 -0.08431372919070 0.06661554000757 0.78727794274777 -55.35949700905178 247.25533584410471)"
        fill="none"
        stroke="rgb(82,0,193)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-dashoffset="200"
        stroke-dasharray="200,3663"
      />
      <path
        id="log-in-girl-7-path6"
        d="M330.384299,475.692916C360.597872,370.181531,811.544089,101.496841,941.231358,321.799623C1070.918627,542.102404,388.520606,1029.847144,313.110408,844.125227C244.882256,676.091413,969.771763,455.678431,1121.393993,768.191802C1273.016222,1080.705173,920.482358,1466.436082,538.241590,1317.786895"
        transform="matrix(0.99643925809352 -0.08431372919070 0.06661554000757 0.78727794274777 -55.35949700905178 247.25533584410471)"
        fill="none"
        stroke="rgb(88,233,255)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-dashoffset="80"
        stroke-dasharray="80,3663"
      />
    </g>
    <g
      id="log-in-girl-7-character_to"
      transform="matrix(1, 0, 0, 1, 767.305115, 790.217163)"
    >
      <g id="log-in-girl-7-character_tr" transform="rotate(0.712854)">
        <g
          id="log-in-girl-7-character"
          transform="scale(0.960000,0.960000) translate(-841.645752,-827.116669)"
        >
          <g
            id="log-in-girl-7-legs_to"
            transform="translate(756.443124,904.469978)"
          >
            <g id="log-in-girl-7-legs_tr" transform="rotate(0)">
              <g
                id="log-in-girl-7-legs"
                transform="translate(-756.443124,-904.469978)"
              >
                <g
                  id="log-in-girl-7-l-leg_tr"
                  transform="translate(776.325144,928.875767) rotate(5.550321)"
                >
                  <g
                    id="log-in-girl-7-l-leg"
                    transform="translate(-776.325144,-928.875767)"
                  >
                    <g id="log-in-girl-7-l-shoe">
                      <path
                        id="log-in-girl-7-path7"
                        d="M968.920000,1344.580000C970.800000,1325.380000,981.540000,1306.860000,981.540000,1306.860000C993.170000,1314.280000,1008.190000,1315.260000,1021.040000,1314.720000C1025.860000,1323.220000,1050.320000,1330.290000,1054.140000,1325.190000C1047.060000,1321.820000,1021.140000,1313.720000,1034.870000,1292.350000L1061.020000,1313.350000C1061.020000,1313.350000,1108.170000,1333.780000,1115.880000,1349.860000C1117.660000,1353.570000,1117.580000,1356.800000,1116.880000,1362.640000C1116.620000,1365.280000,968.580000,1348.060000,968.920000,1344.580000Z"
                        fill="rgb(45,110,245)"
                        stroke="none"
                        stroke-width="1"
                      />
                      <path
                        id="log-in-girl-7-path8"
                        d="M968.920000,1344.580000C968.535601,1347.836701,968.975745,1351.137786,970.200000,1354.180000C970.943331,1355.772346,972.455456,1356.868637,974.200000,1357.080000L1110.200000,1374.080000C1112.959084,1374.380694,1115.468000,1372.461594,1115.900000,1369.720000C1116.270000,1367.720000,1116.670000,1365.260000,1116.960000,1362.660000Z"
                        fill="url(#log-in-girl-7-path8-fill)"
                        stroke="none"
                        stroke-width="1"
                      />
                      <polygon
                        id="log-in-girl-7-polygon1"
                        points="1030.930000,1303.020000 1095.230000,1345.520000 983.470000,1330.630000 981.540000,1306.860000 1030.930000,1303.020000"
                        fill="url(#log-in-girl-7-polygon1-fill)"
                        stroke="none"
                        stroke-width="1"
                      />
                    </g>
                    <polygon
                      id="log-in-girl-7-polygon2"
                      points="981.540000,1306.860000 980.050000,1245.590000 1024.110000,1256.790000 1030.930000,1303.020000 981.540000,1306.860000"
                      fill="rgb(222,130,107)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <path
                      id="log-in-girl-7-path9"
                      d="M704.560000,987.420000C704.560000,987.420000,773.311784,890.169114,777.301784,868.609114C839.243442,883.223956,998.760000,973.840000,1016.970000,1004.350000C1045.970000,1052.940000,1036.690000,1262.680000,1032.860000,1274.350000C1029.030000,1286.020000,978.630000,1283.950000,977.050000,1275.450000L956.770000,1083.340000C956.084944,1075.924082,951.162508,1069.577962,944.150000,1067.070000C910.690000,1055.640000,812.260000,1022.430000,776.870000,1014.750000C734,1005.410000,704.560000,987.420000,704.560000,987.420000Z"
                      fill="url(#log-in-girl-7-path9-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <path
                      id="log-in-girl-7-path10"
                      d="M1017,1004.350000C1013.250000,998.060000,1003.930000,989.350000,991,979.350000C966.180000,1015.350000,994,1058.780000,1030.780000,1054.280000C1027.650000,1032.590000,1023.190000,1014.760000,1017,1004.350000Z"
                      fill="url(#log-in-girl-7-path10-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                  </g>
                </g>
                <path
                  id="log-in-girl-7-path11"
                  d="M693.725003,863.948943C722.565003,841.448943,753.886971,836.561838,772.016971,846.841838C790.146971,857.121838,823.870000,895.420000,811.280000,935.960000C798.540000,976.960000,681.110000,976.310000,681.110000,976.310000C681.110000,976.310000,645.295628,943.402884,693.725003,863.948943Z"
                  fill="url(#log-in-girl-7-path11-fill)"
                  stroke="none"
                  stroke-width="1"
                />
                <path
                  id="log-in-girl-7-path12"
                  d="M772.570000,991.070000C737.520000,1020,688,1015,662.080000,980.190000C636.160000,945.380000,642.948433,894.089034,673.125498,859.449419C702.805498,835.119419,734.130000,832.930000,762.060000,835.110000C767.190000,835.530000,809.360000,960.700000,772.570000,991.070000Z"
                  fill="url(#log-in-girl-7-path12-fill)"
                  stroke="none"
                  stroke-width="1"
                />
                <g
                  id="log-in-girl-7-r-leg_tr"
                  transform="translate(716.717490,938.094892) rotate(0)"
                >
                  <g
                    id="log-in-girl-7-r-leg"
                    transform="translate(-716.717490,-938.094892)"
                  >
                    <polygon
                      id="log-in-girl-7-polygon3"
                      points="634,1331.940000 677.720000,1291.340000 697.890000,1333.960000 668.560000,1369.180000 634,1331.940000"
                      fill="rgb(222,130,107)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <g id="log-in-girl-7-r-shoe">
                      <path
                        id="log-in-girl-7-path13"
                        d="M598.360000,1346.060000C613.580000,1335.220000,634,1331.940000,634,1331.940000C636.060000,1346.060000,645,1358.770000,653.670000,1368.760000C650.570000,1378.090000,661.140000,1402.310000,667.320000,1402.100000C665.230000,1394.240000,654.440000,1368.170000,678.870000,1365.480000L680.350000,1400.040000C680.350000,1400.040000,695.770000,1451.040000,689.010000,1467.630000C687.450000,1471.440000,685.010000,1473.460000,680.350000,1476.630000C678.270000,1478.110000,595.610000,1348,598.360000,1346.060000Z"
                        fill="rgb(45,110,245)"
                        stroke="none"
                        stroke-width="1"
                      />
                      <polygon
                        id="log-in-girl-7-polygon4"
                        points="668.560000,1369.180000 678.920000,1448.210000 617.900000,1348.780000 634,1331.940000 668.560000,1369.180000"
                        fill="url(#log-in-girl-7-polygon4-fill)"
                        stroke="none"
                        stroke-width="1"
                      />
                      <path
                        id="log-in-girl-7-path14"
                        d="M598.370000,1346.060000C595.709866,1347.875891,593.577684,1350.363437,592.190000,1353.270000C591.504863,1354.936895,591.676584,1356.833289,592.650000,1358.350000L667.650000,1478.590000C668.323131,1479.733650,669.423291,1480.562797,670.708134,1480.894793C671.992976,1481.226789,673.357096,1481.034398,674.500000,1480.360000C676.200000,1479.360000,678.240000,1478.110000,680.330000,1476.680000Z"
                        fill="url(#log-in-girl-7-path14-fill)"
                        stroke="none"
                        stroke-width="1"
                      />
                    </g>
                    <path
                      id="log-in-girl-7-path15"
                      d="M869.270000,1134.590000C873.920000,1207.110000,712.710000,1337.100000,693.370000,1348.780000C674.370000,1347.980000,653.370000,1324.380000,655.370000,1306.220000C681.020000,1271.130000,748.820000,1182.220000,773.210000,1149.310000C779.279422,1141.037406,779.008319,1129.712691,772.550000,1121.740000C745.710000,1089.070000,677.830000,1005.370000,653.460000,965.250000C709.040000,954.150000,781.790000,898.070000,781.790000,898.070000C781.790000,898.070000,865.050000,1068.830000,869.270000,1134.590000Z"
                      fill="url(#log-in-girl-7-path15-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <path
                      id="log-in-girl-7-path16"
                      d="M869.270000,1134.590000C868.690000,1125.460000,866.570000,1114.300000,863.370000,1101.860000C813.170000,1114.340000,803,1173.590000,839.470000,1207.190000L839.470000,1207.190000C858.230000,1181,870.610000,1155.430000,869.270000,1134.590000Z"
                      fill="url(#log-in-girl-7-path16-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            id="log-in-girl-7-torso_tr"
            transform="translate(739.434442,829.237049) rotate(0)"
          >
            <g
              id="log-in-girl-7-torso"
              transform="translate(-739.434442,-829.237049)"
            >
              <g
                id="log-in-girl-7-l-arm_to"
                transform="translate(751.929314,639.600551)"
              >
                <g id="log-in-girl-7-l-arm_tr" transform="rotate(18.682774)">
                  <g
                    id="log-in-girl-7-l-arm"
                    transform="translate(-757.860839,-640.237351)"
                  >
                    <path
                      id="log-in-girl-7-path17"
                      d="M767.410000,611.870000L767.410000,611.870000C767.196783,609.017117,769.319597,606.524148,772.170000,606.280000L782.500000,605.670000C785.352052,605.580195,787.737586,607.818031,787.830000,610.670000L787.830000,610.670000C788.112779,614.648908,785.153549,618.119885,781.180000,618.470000L774.840000,618.840000C772.930174,618.901984,771.074037,618.202247,769.680424,616.894914C768.286811,615.587581,767.470035,613.779888,767.410000,611.870000Z"
                      transform="matrix(1 0 0 1 -0.21533054074803 -1.40631269512892)"
                      fill="rgb(44,82,170)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <path
                      id="log-in-girl-7-path18"
                      d="M917.910000,634.680000L917.910000,634.680000C918.910000,652.680000,905.780000,668,888.670000,669.010000L759.903361,675.740493Q732.173270,675.486126,727.071293,645.088543C726.121293,627.088543,739.201293,611.768543,756.311293,610.768543L885.240000,604C902.310000,603,917,616.730000,917.910000,634.680000Z"
                      fill="url(#log-in-girl-7-path18-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <path
                      id="log-in-girl-7-path19"
                      d="M728.510000,647.190000L728.510000,647.190000C729.930000,665.190000,745.010000,678.660000,762.200000,677.190000L825.310000,672.800000L821.071224,607.271159L754.810000,610.690000C737.620000,612.140000,727.090000,629.150000,728.510000,647.190000Z"
                      fill="url(#log-in-girl-7-path19-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <g
                      id="log-in-girl-7-l-elbow_tr"
                      transform="translate(888.953721,639.249612) rotate(4.559080)"
                    >
                      <g
                        id="log-in-girl-7-l-elbow"
                        transform="translate(-888.953721,-639.249612)"
                      >
                        <path
                          id="log-in-girl-7-path20"
                          d="M870.494937,594.076903L940.110000,454.760000C944.470000,445.020000,955.520000,440.840000,964.790000,445.420000C973.340000,449.640000,977.390000,459.850000,974.540000,469.050000Q940.559628,576.101596,926.216312,622.049837C921.111521,638.402834,906.817815,649.622838,884.975603,641.489316C863.133390,633.355794,864.373739,606.986105,870.494937,594.076903Z"
                          transform="matrix(1 0 0 1 -9.04269529354769 23.09594832807124)"
                          fill="url(#log-in-girl-7-path20-fill)"
                          stroke="none"
                          stroke-width="1"
                        />
                        <g
                          id="log-in-girl-7-l-hand_tr"
                          transform="translate(951.265381,489.201854) rotate(-39.949059)"
                        >
                          <g
                            id="log-in-girl-7-l-hand"
                            transform="translate(-951.265381,-489.201854)"
                          >
                            <g
                              id="log-in-girl-7-path21_tr"
                              transform="translate(959.664380,440.493236) rotate(0)"
                            >
                              <path
                                id="log-in-girl-7-path21"
                                d="M963.300000,414.630000L985.550000,370.820000C986.154692,369.569219,987.246158,368.621372,988.569345,368.197952C989.892531,367.774533,991.331502,367.912639,992.550000,368.580000C994.883713,369.933264,995.915120,372.762266,995,375.300000L978.420000,421.790000C977.723805,423.919731,976.186434,425.671814,974.165273,426.638949C972.144112,427.606084,969.815225,427.704023,967.720000,426.910000C963.356188,425.069864,961.211058,420.123290,962.850000,415.680000C962.981712,415.322442,963.131915,414.971970,963.300000,414.630000Z"
                                transform="translate(-968.707076,-417.397287)"
                                fill="rgb(243,172,154)"
                                stroke="none"
                                stroke-width="1"
                              />
                            </g>
                            <path
                              id="log-in-girl-7-path22"
                              d="M1000,414.610000C994.560000,422.610000,987.560000,431.310000,987.560000,431.310000C987.560000,431.310000,999.890000,389.640000,1004.890000,375.560000C1009.890000,361.480000,1003.340000,355.770000,995.040000,366.740000C986.740000,377.710000,945.780000,441.440000,945.780000,441.440000C925.340000,475.110000,976.860000,498.500000,998.440000,458.250000C998.440000,458.250000,1005.780000,440.250000,1011.440000,422.250000C1015.700000,408.540000,1005.750000,406.140000,1000,414.610000Z"
                              transform="matrix(1 0 0 1 -9.04269529354769 23.09594832807136)"
                              fill="rgb(243,172,154)"
                              stroke="none"
                              stroke-width="1"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="log-in-girl-7-torso2">
                <path
                  id="log-in-girl-7-rectangle"
                  d="M-9.669800,-103.949577L96.164411,-98.146367L108.300003,81.750000C48.011637,66.552970,-29.013673,132.371444,-70.404843,94.181592C-3.328355,62.440260,12.308994,-37.157726,-9.669800,-103.949577Z"
                  transform="matrix(1 0 0 1 636.36999511718750 566.89001464843750)"
                  fill="rgb(159,94,192)"
                  stroke="none"
                  stroke-width="1"
                />
                <path
                  id="log-in-girl-7-path23"
                  d="M757.420000,611.230000C776.930000,628.500000,791.770000,662.580000,800.590000,679.550000C818.220000,713.480000,774.396132,825.743468,774.396132,825.743468L668.011154,840.503386C654.631154,792.373386,630.770000,758.700000,616,719.680000C604.440000,689.240000,602.897702,653.186444,623.334703,636.768661C656.226311,610.345641,742.600000,606.460000,757.420000,611.230000Z"
                  fill="url(#log-in-girl-7-path23-fill)"
                  stroke="none"
                  stroke-width="1"
                />
                <path
                  id="log-in-girl-7-path24"
                  d="M709.430000,794.720000C702.680000,788.670000,679.700000,775.890000,686.280000,747.310000C692.860000,718.730000,695.280000,644.910000,644.050000,628.440000L676.387273,615.002988C660.505485,618.159258,635.709723,626.696044,621.795930,637.800576C601.575226,653.938624,604.500000,689.530000,615.970000,719.640000C630.780000,758.640000,654.641154,792.333386,668.021154,840.463386L705.160000,833C708.500000,831.120000,711.020000,829.650000,712.270000,828.820000C720.920000,823.140000,716.170000,800.770000,709.430000,794.720000Z"
                  fill="url(#log-in-girl-7-path24-fill)"
                  stroke="none"
                  stroke-width="1"
                />
                <path
                  id="log-in-girl-7-path25"
                  d="M735.240000,669.860000C731.613837,686.897162,726.542148,703.594259,720.080000,719.770000C729.610000,728.460000,741.520000,744.110000,754.350000,762.420000C764.440000,743.240000,770.890000,723.720000,773.530000,703.830000C764.090000,691.850000,750.920000,680.610000,735.240000,669.860000Z"
                  fill="url(#log-in-girl-7-path25-fill)"
                  stroke="none"
                  stroke-width="1"
                />
                <path
                  id="log-in-girl-7-path26"
                  d="M741.868709,609.533314C753.221435,616.777700,735.390000,670.500000,735.390000,670.500000C735.390000,670.500000,666.219090,618.481106,640.470106,626.694707C648.668421,622.993533,672.502439,613.481965,700.498893,610.920639C728.495347,608.359312,739.067797,609.364020,741.868709,609.533314Z"
                  fill="url(#log-in-girl-7-path26-fill)"
                  stroke="none"
                  stroke-width="1"
                />
                <path
                  id="log-in-girl-7-path27"
                  d=""
                  fill="url(#log-in-girl-7-path27-fill)"
                  stroke="none"
                  stroke-width="1"
                />
              </g>
              <g
                id="log-in-girl-7-r-arm_to"
                transform="translate(630.907199,664.344330)"
              >
                <g id="log-in-girl-7-r-arm_tr" transform="rotate(0)">
                  <g
                    id="log-in-girl-7-r-arm"
                    transform="translate(-630.907199,-664.344330)"
                  >
                    <path
                      id="log-in-girl-7-path28"
                      d="M540.930000,839.330000L540.930000,839.330000C525.870000,830.780000,520.250000,811.030000,528.400000,795.210000L604.270000,647.860000C612.410000,632.040000,631.270000,626.150000,646.270000,634.700000L646.270000,634.700000C661.340000,643.250000,666.950000,663,658.810000,678.820000L583,826.170000C574.820000,842,556,847.880000,540.930000,839.330000Z"
                      fill="url(#log-in-girl-7-path28-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <path
                      id="log-in-girl-7-path29"
                      d="M646.400000,633L646.400000,633C630.640000,624,610.960000,630.220000,602.400000,646.770000C602.400000,646.770000,591.520000,668.770000,578.470000,697C588.150000,700.340000,623.800000,716.870000,632.190000,732.160000L659.480000,679.160000C668,662.600000,662.160000,641.940000,646.400000,633Z"
                      fill="url(#log-in-girl-7-path29-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <rect
                      id="log-in-girl-7-rect1"
                      width="41.731699"
                      height="22.056285"
                      rx="11.560000"
                      ry="11.560000"
                      transform="matrix(0.94173761629286 0.33634842360125 -0.33634842360125 0.94173761629286 590.73359567343334 649.28404298921498)"
                      fill="rgb(45,110,245)"
                      stroke="none"
                      stroke-width="0"
                    />
                    <g
                      id="log-in-girl-7-r-elbow_tr"
                      transform="translate(555.560430,811.826273) rotate(0)"
                    >
                      <g
                        id="log-in-girl-7-r-elbow"
                        transform="translate(-555.560430,-811.826273)"
                      >
                        <path
                          id="log-in-girl-7-path30"
                          d="M542.280000,784.210000L654.680000,718.330000C663.680000,713.080000,674.960000,716.450000,679.970000,725.860000C684.366091,734.130004,682.494318,744.337791,675.450000,750.510000Q607.352090,811.301334,576.421438,835.697961C557.883473,850.319826,539.343473,840.344414,530.734139,829.407967C522.124806,818.471521,520.240502,797.856148,542.280000,784.210000Z"
                          fill="rgb(243,172,154)"
                          stroke="none"
                          stroke-width="1"
                        />
                        <g
                          id="log-in-girl-7-r-hand_tr"
                          transform="translate(667.004429,733.795561) rotate(0)"
                        >
                          <g
                            id="log-in-girl-7-r-hand"
                            transform="translate(-667.004429,-733.795561)"
                          >
                            <g
                              id="log-in-girl-7-path31_tr"
                              transform="translate(673.815588,735.693333) rotate(0)"
                            >
                              <path
                                id="log-in-girl-7-path31"
                                d="M667.670000,726L710.250000,696.600000C714.358024,694.028315,718.577792,695.590677,720.490000,698.810000C722.458439,701.975507,721.999407,706.077851,719.380000,708.730000L682.280000,745.420000C675.871947,751.930972,670.332417,748.914382,665.550000,744.890000C661.078903,739.815942,659.676735,732.485617,667.670000,726Z"
                                transform="translate(-676.290926,-735.629270)"
                                fill="rgb(243,172,154)"
                                stroke="none"
                                stroke-width="1"
                              />
                            </g>
                            <path
                              id="log-in-girl-7-path32"
                              d="M687.730000,703.500000C687.730000,703.500000,703.240000,665.730000,708.250000,651.650000C713.260000,637.570000,701.250000,626.240000,692.940000,637.210000C684.630000,648.180000,655.370000,693.150000,645.940000,713.630000C642.940000,720.150000,637.610000,738.010000,653.750000,741.400000C669.890000,744.790000,682.550000,722.370000,687.730000,703.500000Z"
                              fill="rgb(243,172,154)"
                              stroke="none"
                              stroke-width="1"
                            />
                            <g
                              id="log-in-girl-7-path33_tr"
                              transform="translate(665.120039,691.853940) rotate(8.121512)"
                            >
                              <path
                                id="log-in-girl-7-path33"
                                d="M675.450000,709.370000L686.400000,658.940000C686.850166,656.734518,688.965952,655.281567,691.185989,655.653369C693.406026,656.025172,694.932972,658.088192,694.640000,660.320000L688.640000,711.570000C688.206456,715.242694,684.877694,717.868544,681.205000,717.435000C677.532306,717.001456,674.906456,713.672694,675.340000,710C675.362502,709.787771,675.399252,709.577297,675.450000,709.370000Z"
                                transform="translate(-682.149619,-708.469197)"
                                fill="rgb(243,172,154)"
                                stroke="none"
                                stroke-width="1"
                              />
                            </g>
                            <path
                              id="log-in-girl-7-path34"
                              d="M667.584446,747.303741C682.213377,732.385991,668.710000,698.380000,668.710000,698.380000C675.710000,681.740000,699.705982,640.427452,706,644.540000C706.570000,639.370000,706.570000,636.120000,703.720000,633.690000C708.250000,635.840000,710.350000,643.400000,709.280000,647.290000C708.590000,653.990000,701.218089,672.796680,695.498089,686.106680Q695.186002,708.785554,695.248710,717.149433C695.311417,725.513311,686.814444,748.825278,667.584446,747.303741"
                              fill="url(#log-in-girl-7-path34-fill)"
                              stroke="none"
                              stroke-width="1"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g
                id="log-in-girl-7-head_tr"
                transform="translate(705.871305,614.365228) rotate(0)"
              >
                <g
                  id="log-in-girl-7-head"
                  transform="translate(-700.802780,-615.187587)"
                >
                  <g id="log-in-girl-7-neck">
                    <path
                      id="log-in-girl-7-path35"
                      d="M728.150000,598.830000L724.050000,621.830000C717.332436,633.013283,678.137133,627.494225,675.787133,612.494225L681.090000,557.440000C685.620000,529.190000,724.772150,541.641433,729.409843,557.872006C734.047537,574.102579,731.095510,583.142498,728.150000,598.830000Z"
                      fill="rgb(243,172,154)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <path
                      id="log-in-girl-7-path36"
                      d="M677.249380,597.796137L675.288458,615.037828C683.938458,633.397828,723.057877,633.543108,725.067877,620.513108L727.799380,600.556137C726.692334,618.878116,682.401981,616.333536,677.249380,597.796137Z"
                      fill="url(#log-in-girl-7-path36-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                    <path
                      id="log-in-girl-7-path37"
                      d="M730.507840,591.319428C724.647654,554.956936,690.184796,543.369049,684.834796,547.709049C679.484796,552.049049,680.695816,559.439251,677.696043,592.904512C676.206079,609.526450,719.257840,597.789428,730.507840,591.319428Z"
                      fill="url(#log-in-girl-7-path37-fill)"
                      stroke="none"
                      stroke-width="1"
                    />
                  </g>
                  <g
                    id="log-in-girl-7-head2_tr"
                    transform="translate(702.441048,545.758393) rotate(0)"
                  >
                    <g
                      id="log-in-girl-7-head2"
                      transform="translate(-702.441048,-545.758393)"
                    >
                      <path
                        id="log-in-girl-7-path38"
                        d="M671.060000,477.070000C642.575621,490.625937,629.276466,546.124853,676.259128,575.998800C682.600462,582.772994,694.726754,588.164941,703.900064,591.666217C721.860328,598.521306,754.788497,590.569468,759.310000,587.450000C765.846774,583.572655,769.770000,560.900000,769.040000,544.820000C768.310000,528.740000,764.660000,511.110000,762.040000,500.650000C759.420000,490.190000,752.330000,471.030000,749.280000,468.220000C741.560000,470,736,462.350000,736,462.350000C721.400000,469.240000,676.410000,472.730000,671.060000,477.070000Z"
                        fill="url(#log-in-girl-7-path38-fill)"
                        stroke="none"
                        stroke-width="1"
                      />
                      <path
                        id="log-in-girl-7-path39"
                        d="M740.170000,448.150000C740.170000,448.150000,747.440000,443.450000,754.110000,448.150000C771,460.060000,767.580000,495.790000,749.060000,498.910000Z"
                        fill="rgb(202,167,218)"
                        stroke="none"
                        stroke-width="1"
                      />
                      <path
                        id="log-in-girl-7-path40"
                        d="M696.650000,573.450000C707.140000,585.320000,744.960000,592.610000,759.310000,587.450000C764.780000,585.450000,769.770000,560.900000,769.040000,544.820000C768.310000,528.740000,764.660000,511.110000,762.040000,500.650000C759.420000,490.190000,752.330000,471.030000,749.280000,468.220000C741.560000,470,736,462.350000,736,462.350000C711.340000,459.540000,686.120000,470.770000,680.770000,475.110000C675.420000,479.450000,682.740000,557.740000,696.650000,573.450000Z"
                        fill="rgb(243,172,154)"
                        stroke="none"
                        stroke-width="1"
                      />
                      <path
                        id="log-in-girl-7-path41"
                        d="M762,500.650000C759.320000,490.180000,752.290000,471.030000,749.240000,468.220000C743.520000,469.550000,738.960000,465.640000,737.010000,463.560000C749.480000,478.620000,747.440000,498.910000,752.010000,508.850000C755.340000,511.950000,763.660000,525.990000,764.210000,529.620000C765.420000,537.620000,755.030000,537.970000,757.210000,544.380000C758.290000,547.530000,765.520000,584.910000,755.910000,588.380000C757.071758,588.165302,758.215169,587.861061,759.330000,587.470000C764.800000,585.470000,769.790000,560.920000,769.060000,544.840000C768.330000,528.760000,764.660000,511.110000,762,500.650000Z"
                        fill="url(#log-in-girl-7-path41-fill)"
                        stroke="none"
                        stroke-width="1"
                      />
                      <g id="log-in-girl-7-glasses">
                        <path
                          id="log-in-girl-7-path42"
                          d="M775.908108,523.745109C772.468328,525.222109,767.663586,525.467396,763.452230,521.886678C757.840140,517.114981,754.313710,507.982340,752.172810,510.071760C749.874840,516.464030,749.252890,530.923330,734.547480,534.892300C719.581990,538.931470,709.358570,529.643100,703.292940,523.390590C699.249190,519.222240,693.730830,518.543130,686.737870,521.353250L668.897870,534.243250Q668.897870,534.243250,667.829019,533.344133C675.219369,525.999673,681.942180,521.708430,685.738500,519.012890C689.450660,516.377110,696.225480,515.005600,700.584610,515.786480C703.971580,516.393210,706.218620,519.733350,708.308500,521.792890C713.711670,527.117620,723.143800,534.196110,735.890360,531.294370C751.048140,527.843710,749.028050,508.823670,753.193710,508.379620C757.427470,507.928310,775.908108,523.745109,775.908108,523.745109Z"
                          fill="url(#log-in-girl-7-path42-fill)"
                          stroke="none"
                          stroke-width="1"
                        />
                        <path
                          id="log-in-girl-7-path43"
                          d="M663,527.200000Q674.106357,516.211029,679.959518,511.517472C686.615693,506.179990,693.662699,498.840004,701.683027,495.930070C712.303517,492.076745,727.088260,489.411011,739.913122,490.362612C741.760353,490.499676,745.937217,492.656780,748.761362,492.073446C751.104040,491.589559,751.525622,489.539154,754.122932,488.554325C759.194558,486.631302,764.633245,487.301228,766.726711,487.686262C771.554903,488.574271,777.618739,520.553459,773.456257,521.309927C761.844310,523.420221,755.588755,505.808684,750.853365,506.399427C747.482321,506.819967,749.814259,525.335904,733.562836,529.455166C720.890901,532.667131,711.688047,525.950533,705.713867,519.691788C703.687909,517.569329,700.978178,514.357937,697.591206,513.751205C693.232083,512.970325,687.343402,514.951751,683.631246,517.587528Q677.936769,521.630832,666.273030,530.992746L663,527.200000Z"
                          transform="matrix(1 0 0 1 2.30850316553585 2.53288818833795)"
                          fill="url(#log-in-girl-7-path43-fill)"
                          stroke="none"
                          stroke-width="1.600000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <g
                          id="log-in-girl-7-mask-group"
                          mask="url(#log-in-girl-7-masks)"
                        >
                          <g
                            id="log-in-girl-7-path44_to"
                            transform="translate(719.807821,512.540409)"
                          >
                            <g
                              id="log-in-girl-7-path44_tr"
                              transform="rotate(-13.563008)"
                            >
                              <path
                                id="log-in-girl-7-path44"
                                d="M714.164040,507.203430C718.787640,505.259430,725.339364,492.448006,720.196422,482.690527C727.070032,481.568477,741.724610,483.321854,742.103610,483.410484C745.961342,504.856008,713.869633,515.130327,726.700459,541.188294C717.828079,540.796284,699.630303,531.712978,697.356723,530.451808Q696.227884,515.555828,714.164040,507.203440Q710.234720,508.855510,714.164040,507.203430Z"
                                transform="translate(-722.793220,-512.748547)"
                                fill="url(#log-in-girl-7-path44-fill)"
                                stroke="none"
                                stroke-width="1.600000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </g>
                          <mask id="log-in-girl-7-masks" mask-type="luminance">
                            <path
                              id="log-in-girl-7-path45"
                              d="M663,527.200000Q674.106357,516.211029,679.959518,511.517472C686.615693,506.179990,693.662699,498.840004,701.683027,495.930070C712.303517,492.076745,727.088260,489.411011,739.913122,490.362612C741.760353,490.499676,745.937217,492.656780,748.761362,492.073446C751.104040,491.589559,751.525622,489.539154,754.122932,488.554325C759.194558,486.631302,764.633245,487.301228,766.726711,487.686262C771.554903,488.574271,777.618739,520.553459,773.456257,521.309927C761.844310,523.420221,755.588755,505.808684,750.853365,506.399427C747.482321,506.819967,749.814259,525.335904,733.562836,529.455166C720.890901,532.667131,711.688047,525.950533,705.713867,519.691788C703.687909,517.569329,700.978178,514.357937,697.591206,513.751205C693.232083,512.970325,687.343402,514.951751,683.631246,517.587528Q677.936769,521.630832,666.273030,530.992746L663,527.200000Z"
                              transform="matrix(1 0 0 1 2.30850316553585 2.53288818833795)"
                              fill="url(#log-in-girl-7-path45-fill)"
                              stroke="none"
                              stroke-width="1.600000"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </mask>
                        </g>
                      </g>
                      <g id="log-in-girl-7-mouth">
                        <path
                          id="log-in-girl-7-path46"
                          d="M731.130000,555.840000C742.060000,553.250000,761.320000,549,767,551.510000C762.520000,557.170000,746.780000,560.670000,731.130000,555.840000Z"
                          fill="url(#log-in-girl-7-path46-fill)"
                          stroke="none"
                          stroke-width="1"
                        />
                        <path
                          id="log-in-girl-7-path47"
                          d="M739.500000,562.720000C744.830000,566.720000,754.790000,570.670000,762.060000,560.030000C752.490000,567.390000,745.220000,563,739.500000,562.720000Z"
                          fill="url(#log-in-girl-7-path47-fill)"
                          stroke="none"
                          stroke-width="1"
                        />
                      </g>
                      <g
                        id="log-in-girl-7-hair_tk"
                        transform="translate(732.607645,458.024355) skewX(1) skewY(0)"
                      >
                        <g id="log-in-girl-7-hair_ts" transform="scale(1,1)">
                          <g
                            id="log-in-girl-7-hair"
                            transform="translate(-731.607016,-456.312332)"
                          >
                            <path
                              id="log-in-girl-7-path48"
                              d="M725.170000,436.810000C709.550000,415.920000,626.260000,427.310000,616.170000,463.560000C609.760000,486.560000,604.460000,534.350000,599.350000,550.740000C594.240000,567.130000,544.940000,610.110000,554.070000,669.230000C555.794215,670.411761,577.092540,678.328207,577.218730,678.032809C603.435527,616.662099,650.316634,610.281435,656.455978,546.714785C662.289931,486.310155,660.749387,435.507762,725.170000,436.810000Z"
                              fill="url(#log-in-girl-7-path48-fill)"
                              stroke="none"
                              stroke-width="1"
                            />
                            <g
                              id="log-in-girl-7-path49_tr"
                              transform="translate(692.109626,433.059743) rotate(0)"
                            >
                              <path
                                id="log-in-girl-7-path49"
                                d="M559.470000,637.750000C529.160000,584.750000,552.860144,561.983824,563.711077,548.950273C583.521966,525.154507,594.432127,515.535062,604.236448,485.449198C622.846008,428.343284,678.374497,419.545433,709.055309,433.020606L706.815309,443.020606C677.012531,420.382152,622.416425,447.949007,612.350000,484.190000C605.947726,507.239369,594.158144,525.798313,570.610000,551.710000C559.269106,564.189188,536.880000,581.520000,561.490000,635.140000Z"
                                transform="translate(-692.109626,-433.059743)"
                                fill="url(#log-in-girl-7-path49-fill)"
                                stroke="none"
                                stroke-width="1"
                              />
                            </g>
                            <path
                              id="log-in-girl-7-path50"
                              d="M673.590719,504.399278C673.590719,504.399278,682.720000,487.670000,686.720000,479.200000C693.440000,464.650000,735.960000,462.350000,735.960000,462.350000C708.320000,426.860000,664.870719,466.979278,673.590719,504.399278Z"
                              fill="url(#log-in-girl-7-path50-fill)"
                              stroke="none"
                              stroke-width="1"
                            />
                            <g
                              id="log-in-girl-7-path51_tr"
                              transform="translate(708.823608,435.898052) rotate(0)"
                            >
                              <path
                                id="log-in-girl-7-path51"
                                d="M528.590000,659.630000C538.530000,601.040000,580.740000,610.440000,593.590000,589.140000C602.400000,574.570000,596.080000,519.140000,604.180000,496.710000C626.560000,434.620000,699.580000,411.330000,725.180000,436.810000L722.940000,446.810000C708.640000,431.630000,649.320000,429.330000,615.940000,488.480000C604.280000,509.130000,619.370000,560.530000,601.190000,593C586.240000,619.710000,546.430000,603.540000,536.480000,662.130000Z"
                                transform="translate(-708.823608,-435.898052)"
                                fill="url(#log-in-girl-7-path51-fill)"
                                stroke="none"
                                stroke-width="1"
                              />
                            </g>
                            <g
                              id="log-in-girl-7-path52_tr"
                              transform="translate(741.877389,461.448006) rotate(0)"
                            >
                              <path
                                id="log-in-girl-7-path52"
                                d="M749.230000,468.220000C749.230000,468.220000,751.330000,431.220000,701.230000,431.130000C651.130000,431.040000,642.100000,484.310000,643.560000,540.480000C645.020000,596.650000,560.640000,616.560000,554.070000,669.230000C553.791873,669.444066,577.218730,678.032809,577.218730,678.032809C601.128730,641.602809,611.752072,636.870459,645.038327,623.716072C672.524700,612.853740,690.010000,604.810000,677.200000,539.820000C666.460000,485.310000,682.390000,462.350000,700.970000,456.470000C725.750000,448.640000,735.970000,462.350000,735.970000,462.350000C739.350000,467.710000,743.820000,469.510000,749.230000,468.220000Z"
                                transform="translate(-741.877389,-461.448006)"
                                fill="url(#log-in-girl-7-path52-fill)"
                                stroke="none"
                                stroke-width="1"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            id="log-in-girl-7-belt_to"
            transform="translate(737.511421,839.512105)"
          >
            <g id="log-in-girl-7-belt_tk" transform="skewX(2) skewY(3)">
              <g id="log-in-girl-7-belt_ts" transform="scale(1,1)">
                <path
                  id="log-in-girl-7-belt"
                  d="M776.214400,817.696761C776.214400,817.696761,779.770471,846.295504,779.770471,846.295504C741.860471,851.295504,699.314668,852.556041,669.224668,862.716041L665.796641,832.223556C700.466641,821.993556,742.434400,820.656761,776.214400,817.696761Z"
                  transform="translate(-737.670013,-835.130035)"
                  fill="url(#log-in-girl-7-belt-fill)"
                  stroke="none"
                  stroke-width="1"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g
      id="log-in-girl-7-stroke2"
      transform="matrix(1.026422, 0.158284, -0.136814, 0.887199, 74.015945, 29.168991)"
    >
      <path
        id="log-in-girl-7-path55"
        d="M330.384299,475.692916C360.597872,370.181531,811.544089,101.496841,941.231358,321.799623C1070.918627,542.102404,388.520606,1029.847144,313.110408,844.125227C244.882256,676.091413,969.771763,455.678431,1121.393993,768.191802C1273.016222,1080.705173,920.482358,1466.436082,538.241590,1317.786895"
        transform="matrix(0.99643925809352 -0.08431372919070 0.06661554000757 0.78727794274777 -55.35949700905178 247.25533584410471)"
        fill="none"
        stroke="rgb(82,0,193)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-dashoffset="200"
        stroke-dasharray="200,3663"
      />
      <path
        id="log-in-girl-7-path56"
        d="M330.384299,475.692916C360.597872,370.181531,811.544089,101.496841,941.231358,321.799623C1070.918627,542.102404,388.520606,1029.847144,313.110408,844.125227C244.882256,676.091413,969.771763,455.678431,1121.393993,768.191802C1273.016222,1080.705173,920.482358,1466.436082,538.241590,1317.786895"
        transform="matrix(0.99643925809352 -0.08431372919070 0.06661554000757 0.78727794274777 -55.35949700905178 247.25533584410471)"
        fill="none"
        stroke="rgb(88,233,255)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-dashoffset="80"
        stroke-dasharray="80,3663"
      />
    </g>
    <path
      id="log-in-girl-7-path57"
      d="M0,432.155860C13.559496,370.332396,156.769308,220.693233,200.493760,370.377580C250.686380,542.204765,29.606092,798.045622,244.621104,938.149385C358.816432,1012.559051,500.559080,913.812760,615.290780,861.661990C746.372660,802.079320,926.771081,724.847718,1056.564200,811.651000C1224.814106,924.173484,1145.899280,1059.528830,1206.597160,1226.448020C1245.491770,1333.408190,1384.211600,1458.098210,1500,1411.782860"
      opacity="0.68"
      fill="none"
      stroke="rgb(127,0,211)"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dashoffset="2843"
      stroke-dasharray="120,2603"
    />
    <path
      id="log-in-girl-7-path58"
      d="M0,432.155860C13.559496,370.332396,156.769308,220.693233,200.493760,370.377580C250.686380,542.204765,29.606092,798.045622,244.621104,938.149385C358.816432,1012.559051,500.559080,913.812760,615.290780,861.661990C746.372660,802.079320,926.771081,724.847718,1056.564200,811.651000C1224.814106,924.173484,1145.899280,1059.528830,1206.597160,1226.448020C1245.491770,1333.408190,1384.211600,1458.098210,1500,1411.782860"
      fill="none"
      stroke="rgb(255,0,237)"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dashoffset="2663"
      stroke-dasharray="30,2603"
    />
    <g id="log-in-girl-7-stroke-32">
      <path
        id="log-in-girl-7-path59"
        d="M1500,573.299570C1405.072895,277.849390,984.064811,151.276513,837.043922,353.138468C712.341947,524.356209,834.220107,719.697883,921.150411,793.460672C1001.894978,861.974691,1161.047872,895.251504,1219.185240,806.463874C1277.322608,717.676244,1206.082925,568.799896,1060.952454,503.253405C841.048473,403.936327,408.279640,518.110168,275.509426,748.933707C206.653791,868.640536,241.510148,1029.908525,384.353117,1092.780822C659.496635,1213.885172,915.060323,889.747926,1205.628239,1068.043619C1423.636973,1201.816194,1369.043469,1471.067160,1304.577049,1600"
        fill="none"
        stroke="rgb(16,65,165)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dashoffset="5972"
        stroke-dasharray="600,4772"
      />
      <path
        id="log-in-girl-7-path60"
        d="M1500,573.299570C1405.072895,277.849390,984.064811,151.276513,837.043922,353.138468C712.341947,524.356209,834.220107,719.697883,921.150411,793.460672C1001.894978,861.974691,1161.047872,895.251504,1219.185240,806.463874C1277.322608,717.676244,1206.082925,568.799896,1060.952454,503.253405C841.048473,403.936327,408.279640,518.110168,275.509426,748.933707C206.653791,868.640536,241.510148,1029.908525,384.353117,1092.780822C659.496635,1213.885172,915.060323,889.747926,1205.628239,1068.043619C1423.636973,1201.816194,1369.043469,1471.067160,1304.577049,1600"
        fill="none"
        stroke="rgb(54,120,255)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dashoffset="5172"
        stroke-dasharray="200,4772"
      />
    </g>
    <g
      id="log-in-girl-7-g1"
      transform="matrix(0.891362, -0.169151, 0.169151, 0.891362, -93.865593, 246.230423)"
    >
      <path
        id="log-in-girl-7-path61"
        d="M0,432.155860C13.559496,370.332396,156.769308,220.693233,200.493760,370.377580C250.686380,542.204765,29.606092,798.045622,244.621104,938.149385C358.816432,1012.559051,500.559080,913.812760,615.290780,861.661990C746.372660,802.079320,926.771081,724.847718,1056.564200,811.651000C1224.814106,924.173484,1145.899280,1059.528830,1206.597160,1226.448020C1245.491770,1333.408190,1384.211600,1458.098210,1500,1411.782860"
        transform="matrix(-1 0 0 1 1510.05472000000009 0)"
        opacity="0.38"
        fill="none"
        stroke="rgb(127,0,211)"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dashoffset="2843"
        stroke-dasharray="120,2603"
      />
      <path
        id="log-in-girl-7-path62"
        d="M0,432.155860C13.559496,370.332396,156.769308,220.693233,200.493760,370.377580C250.686380,542.204765,29.606092,798.045622,244.621104,938.149385C358.816432,1012.559051,500.559080,913.812760,615.290780,861.661990C746.372660,802.079320,926.771081,724.847718,1056.564200,811.651000C1224.814106,924.173484,1145.899280,1059.528830,1206.597160,1226.448020C1245.491770,1333.408190,1384.211600,1458.098210,1500,1411.782860"
        transform="matrix(-1 0 0 1 1510.05472000000009 0)"
        fill="none"
        stroke="rgb(141,0,255)"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dashoffset="2663"
        stroke-dasharray="30,2603"
      />
    </g>
  </svg>
</template>

<style>
#log-in-girl-7-path1_tr {
  animation: log-in-girl-7-path1_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path1_tr__tr {
  0% {
    transform: translate(721.694252px, 768.442875px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(721.694252px, 768.442875px) rotate(8deg);
  }
  100% {
    transform: translate(721.694252px, 768.442875px) rotate(0deg);
  }
}
#log-in-girl-7-path2_tr {
  animation: log-in-girl-7-path2_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path2_tr__tr {
  0% {
    transform: translate(764.614975px, 792.540727px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(764.614975px, 792.540727px) rotate(-4deg);
  }
  100% {
    transform: translate(764.614975px, 792.540727px) rotate(0deg);
  }
}
#log-in-girl-7-path2 {
  animation: log-in-girl-7-path2_s_do 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path2_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    stroke-dashoffset: 60;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#log-in-girl-7-ellipse1_ts {
  animation: log-in-girl-7-ellipse1_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse1_ts__ts {
  0% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  12.500000% {
    transform: translate(295.100006px, 872.539978px) scale(1, 1);
  }
  33.333333% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  59.166667% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  71.666667% {
    transform: translate(295.100006px, 872.539978px) scale(1, 1);
  }
  92.500000% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
  }
  100% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
  }
}
#log-in-girl-7-ellipse2_ts {
  animation: log-in-girl-7-ellipse2_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse2_ts__ts {
  0% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  12.500000% {
    transform: translate(295.100006px, 872.539978px) scale(1, 1);
  }
  33.333333% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  59.166667% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  71.666667% {
    transform: translate(295.100006px, 872.539978px) scale(1, 1);
  }
  92.500000% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
  }
  100% {
    transform: translate(295.100006px, 872.539978px) scale(0.733169, 0.733169);
  }
}
#log-in-girl-7-ellipse3_ts {
  animation: log-in-girl-7-ellipse3_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse3_ts__ts {
  0% {
    transform: translate(1269.699951px, 247.059998px) scale(0.576432, 0.576432);
  }
  49.166667% {
    transform: translate(1269.699951px, 247.059998px) scale(0.576432, 0.576432);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  61.666667% {
    transform: translate(1269.699951px, 247.059998px) scale(0, 0);
  }
  82.500000% {
    transform: translate(1269.699951px, 247.059998px) scale(0.576432, 0.576432);
  }
  100% {
    transform: translate(1269.699951px, 247.059998px) scale(0.576432, 0.576432);
  }
}
#log-in-girl-7-ellipse4_ts {
  animation: log-in-girl-7-ellipse4_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse4_ts__ts {
  0% {
    transform: translate(446.983927px, 963.74372px) scale(0.576432, 0.576432);
  }
  8.333333% {
    transform: translate(446.983927px, 963.74372px) scale(0.576432, 0.576432);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  20.833333% {
    transform: translate(446.983927px, 963.74372px) scale(0, 0);
  }
  41.666667% {
    transform: translate(446.983927px, 963.74372px) scale(0.576432, 0.576432);
  }
  100% {
    transform: translate(446.983927px, 963.74372px) scale(0.576432, 0.576432);
  }
}
#log-in-girl-7-ellipse5_ts {
  animation: log-in-girl-7-ellipse5_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse5_ts__ts {
  0% {
    transform: translate(1232.819885px, 582.52002px) scale(0.576432, 0.576432);
  }
  9.166667% {
    transform: translate(1232.819885px, 582.52002px) scale(0.576432, 0.576432);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  27.500000% {
    transform: translate(1232.819885px, 582.52002px) scale(0, 0);
  }
  54.166667% {
    transform: translate(1232.819885px, 582.52002px) scale(0.576432, 0.576432);
  }
  100% {
    transform: translate(1232.819885px, 582.52002px) scale(0.576432, 0.576432);
  }
}
#log-in-girl-7-ellipse6_ts {
  animation: log-in-girl-7-ellipse6_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse6_ts__ts {
  0% {
    transform: translate(120.150002px, 700.400024px) scale(0.576432, 0.576432);
  }
  23.333333% {
    transform: translate(120.150002px, 700.400024px) scale(0.576432, 0.576432);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  41.666667% {
    transform: translate(120.150002px, 700.400024px) scale(0, 0);
  }
  68.333333% {
    transform: translate(120.150002px, 700.400024px) scale(0.576432, 0.576432);
  }
  100% {
    transform: translate(120.150002px, 700.400024px) scale(0.576432, 0.576432);
  }
}
#log-in-girl-7-ellipse7_ts {
  animation: log-in-girl-7-ellipse7_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse7_ts__ts {
  0% {
    transform: translate(1217.279968px, 878.75px) scale(0.371945, 0.371945);
  }
  59.166667% {
    transform: translate(1217.279968px, 878.75px) scale(0.371945, 0.371945);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  77.500000% {
    transform: translate(1217.279968px, 878.75px) scale(1, 1);
  }
  100% {
    transform: translate(1217.279968px, 878.75px) scale(0.371945, 0.371945);
  }
}
#log-in-girl-7-ellipse8_ts {
  animation: log-in-girl-7-ellipse8_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse8_ts__ts {
  0% {
    transform: translate(1217.280029px, 878.75px) scale(0.452927, 0.452927);
  }
  59.166667% {
    transform: translate(1217.280029px, 878.75px) scale(0.452927, 0.452927);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  77.500000% {
    transform: translate(1217.280029px, 878.75px) scale(1, 1);
  }
  100% {
    transform: translate(1217.280029px, 878.75px) scale(0.452927, 0.452927);
  }
}
#log-in-girl-7-ellipse9_ts {
  animation: log-in-girl-7-ellipse9_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse9_ts__ts {
  0% {
    transform: translate(527.990021px, 235.740005px) scale(0.579296, 0.579296);
  }
  15% {
    transform: translate(527.990021px, 235.740005px) scale(0.579296, 0.579296);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  37.500000% {
    transform: translate(527.990021px, 235.740005px) scale(1, 1);
  }
  62.500000% {
    transform: translate(527.990021px, 235.740005px) scale(0.579296, 0.579296);
  }
  100% {
    transform: translate(527.990021px, 235.740005px) scale(0.579296, 0.579296);
  }
}
#log-in-girl-7-ellipse10_ts {
  animation: log-in-girl-7-ellipse10_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse10_ts__ts {
  0% {
    transform: translate(527.98999px, 235.740013px) scale(0.733169, 0.733169);
  }
  15% {
    transform: translate(527.98999px, 235.740013px) scale(0.733169, 0.733169);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  37.500000% {
    transform: translate(527.98999px, 235.740013px) scale(1, 1);
  }
  62.500000% {
    transform: translate(527.98999px, 235.740013px) scale(0.733169, 0.733169);
  }
  100% {
    transform: translate(527.98999px, 235.740013px) scale(0.733169, 0.733169);
  }
}
#log-in-girl-7-path3 {
  animation-name: log-in-girl-7-path3_s_w, log-in-girl-7-path3_s_do;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path3_s_w {
  0% {
    stroke-width: 12;
  }
  50% {
    stroke-width: 12;
  }
  57.500000% {
    stroke-width: 6;
  }
  61.666667% {
    stroke-width: 12;
  }
  100% {
    stroke-width: 12;
  }
}
@keyframes log-in-girl-7-path3_s_do {
  0% {
    stroke-dashoffset: 5972;
  }
  50% {
    stroke-dashoffset: 5972;
  }
  66.666667% {
    stroke-dashoffset: 600;
  }
  100% {
    stroke-dashoffset: 600;
  }
}
#log-in-girl-7-path4 {
  animation-name: log-in-girl-7-path4_s_w, log-in-girl-7-path4_s_do;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path4_s_w {
  0% {
    stroke-width: 12;
  }
  50% {
    stroke-width: 12;
  }
  57.500000% {
    stroke-width: 6;
  }
  61.666667% {
    stroke-width: 12;
  }
  100% {
    stroke-width: 12;
  }
}
@keyframes log-in-girl-7-path4_s_do {
  0% {
    stroke-dashoffset: 5172;
  }
  49.916667% {
    stroke-dashoffset: 5172;
  }
  65.250000% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 200;
  }
}
#log-in-girl-7-path5 {
  animation-name: log-in-girl-7-path5_s_w, log-in-girl-7-path5_s_do;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path5_s_w {
  0% {
    stroke-width: 12;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  4.166667% {
    stroke-width: 15;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  7.500000% {
    stroke-width: 10;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  10.833333% {
    stroke-width: 15;
  }
  18.333333% {
    stroke-width: 12;
  }
  100% {
    stroke-width: 12;
  }
}
@keyframes log-in-girl-7-path5_s_do {
  0% {
    stroke-dashoffset: 200;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  18.333333% {
    stroke-dashoffset: 4063;
  }
  100% {
    stroke-dashoffset: 4063;
  }
}
#log-in-girl-7-path6 {
  animation-name: log-in-girl-7-path6_s_w, log-in-girl-7-path6_s_do;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path6_s_w {
  0% {
    stroke-width: 12;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  4.166667% {
    stroke-width: 15;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  7.500000% {
    stroke-width: 10;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  10.833333% {
    stroke-width: 15;
  }
  18.333333% {
    stroke-width: 12;
  }
  100% {
    stroke-width: 12;
  }
}
@keyframes log-in-girl-7-path6_s_do {
  0% {
    stroke-dashoffset: 80;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  17.500000% {
    stroke-dashoffset: 3823;
  }
  100% {
    stroke-dashoffset: 3823;
  }
}
#log-in-girl-7-character_to {
  animation: log-in-girl-7-character_to__to 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-character_to__to {
  0% {
    transform: translate(778.790115px, 814.400955px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  25% {
    transform: translate(768.154989px, 785.033458px);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  50% {
    transform: translate(763.685205px, 812.298016px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  75% {
    transform: translate(768.154989px, 785.033458px);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  100% {
    transform: translate(778.790115px, 814.400955px);
  }
}
#log-in-girl-7-character_tr {
  animation: log-in-girl-7-character_tr__tr 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-character_tr__tr {
  0% {
    transform: rotate(0.712854deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: rotate(-1.486471deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.38, 1);
  }
  100% {
    transform: rotate(0.712854deg);
  }
}
#log-in-girl-7-legs_to {
  animation: log-in-girl-7-legs_to__to 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-legs_to__to {
  0% {
    transform: translate(756.443124px, 904.469978px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(751.551111px, 893.951668px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: translate(756.443124px, 904.469978px);
  }
}
#log-in-girl-7-legs_tr {
  animation: log-in-girl-7-legs_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-legs_tr__tr {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: rotate(-6deg);
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: rotate(-6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
#log-in-girl-7-l-leg_tr {
  animation: log-in-girl-7-l-leg_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-l-leg_tr__tr {
  0% {
    transform: translate(776.325144px, 928.875767px) rotate(5.550321deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(776.325144px, 928.875767px) rotate(10.775423deg);
  }
  100% {
    transform: translate(776.325144px, 928.875767px) rotate(5.550321deg);
  }
}
#log-in-girl-7-r-leg_tr {
  animation: log-in-girl-7-r-leg_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-r-leg_tr__tr {
  0% {
    transform: translate(716.71749px, 938.094892px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(716.71749px, 938.094892px) rotate(-8deg);
  }
  100% {
    transform: translate(716.71749px, 938.094892px) rotate(0deg);
  }
}
#log-in-girl-7-torso_tr {
  animation: log-in-girl-7-torso_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-torso_tr__tr {
  0% {
    transform: translate(739.434442px, 829.237049px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(739.434442px, 829.237049px) rotate(6deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: translate(739.434442px, 829.237049px) rotate(0deg);
  }
}
#log-in-girl-7-l-arm_to {
  animation: log-in-girl-7-l-arm_to__to 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-l-arm_to__to {
  0% {
    transform: translate(751.929314px, 639.600551px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(757.860839px, 640.237351px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(751.180414px, 639.52015px);
  }
  100% {
    transform: translate(751.929314px, 639.600551px);
  }
}
#log-in-girl-7-l-arm_tr {
  animation: log-in-girl-7-l-arm_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-l-arm_tr__tr {
  0% {
    transform: rotate(18.682774deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  4.166667% {
    transform: rotate(23.009626deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  10% {
    transform: rotate(10.97017deg);
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: rotate(21.041617deg);
  }
  100% {
    transform: rotate(18.682774deg);
  }
}
#log-in-girl-7-l-elbow_tr {
  animation: log-in-girl-7-l-elbow_tr__tr 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-l-elbow_tr__tr {
  0% {
    transform: translate(888.953721px, 639.249612px) rotate(4.55908deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  4.166667% {
    transform: translate(888.953721px, 639.249612px) rotate(-19.263395deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  10% {
    transform: translate(888.953721px, 639.249612px) rotate(2.018707deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  16.666667% {
    transform: translate(888.953721px, 639.249612px) rotate(-7.49257deg);
  }
  50% {
    transform: translate(888.953721px, 639.249612px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(888.953721px, 639.249612px) rotate(5.134698deg);
  }
  100% {
    transform: translate(888.953721px, 639.249612px) rotate(4.55908deg);
  }
}
#log-in-girl-7-l-hand_tr {
  animation: log-in-girl-7-l-hand_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-l-hand_tr__tr {
  0% {
    transform: translate(951.265381px, 489.201854px) rotate(-39.949059deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  4.166667% {
    transform: translate(951.265381px, 489.201854px) rotate(19.901476deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  10% {
    transform: translate(951.265381px, 489.201854px) rotate(12.012165deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  27.500000% {
    transform: translate(951.265381px, 489.201854px) rotate(-11.390477deg);
  }
  50% {
    transform: translate(951.265381px, 489.201854px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(951.265381px, 489.201854px) rotate(-44.992934deg);
  }
  100% {
    transform: translate(951.265381px, 489.201854px) rotate(-39.949059deg);
  }
}
#log-in-girl-7-path21_tr {
  animation: log-in-girl-7-path21_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path21_tr__tr {
  0% {
    transform: translate(959.66438px, 440.493236px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  4.166667% {
    transform: translate(959.66438px, 440.493236px) rotate(10.639906deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  16.666667% {
    transform: translate(959.66438px, 440.493236px) rotate(10.639906deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  29.166667% {
    transform: translate(959.66438px, 440.493236px) rotate(-1.731745deg);
  }
  100% {
    transform: translate(959.66438px, 440.493236px) rotate(0deg);
  }
}
#log-in-girl-7-r-arm_to {
  animation: log-in-girl-7-r-arm_to__to 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-r-arm_to__to {
  0% {
    transform: translate(630.907199px, 664.34433px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(640.151692px, 667.069631px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(630.907199px, 664.34433px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(640.151692px, 667.069631px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: translate(630.907199px, 664.34433px);
  }
}
#log-in-girl-7-r-arm_tr {
  animation: log-in-girl-7-r-arm_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-r-arm_tr__tr {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: rotate(2.801191deg);
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: rotate(2.801191deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
#log-in-girl-7-r-elbow_tr {
  animation: log-in-girl-7-r-elbow_tr__tr 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-r-elbow_tr__tr {
  0% {
    transform: translate(555.56043px, 811.826273px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(555.56043px, 811.826273px) rotate(16.902344deg);
  }
  50% {
    transform: translate(555.56043px, 811.826273px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(555.56043px, 811.826273px) rotate(16.902344deg);
  }
  100% {
    transform: translate(555.56043px, 811.826273px) rotate(0deg);
  }
}
#log-in-girl-7-r-hand_tr {
  animation: log-in-girl-7-r-hand_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-r-hand_tr__tr {
  0% {
    transform: translate(667.004429px, 733.795561px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(667.004429px, 733.795561px) rotate(-30.290707deg);
  }
  50% {
    transform: translate(667.004429px, 733.795561px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(667.004429px, 733.795561px) rotate(-30.290707deg);
  }
  100% {
    transform: translate(667.004429px, 733.795561px) rotate(0deg);
  }
}
#log-in-girl-7-path31_tr {
  animation: log-in-girl-7-path31_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path31_tr__tr {
  0% {
    transform: translate(673.815588px, 735.693333px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(673.815588px, 735.693333px) rotate(7.424412deg);
  }
  50% {
    transform: translate(673.815588px, 735.693333px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(673.815588px, 735.693333px) rotate(7.424412deg);
  }
  100% {
    transform: translate(673.815588px, 735.693333px) rotate(0deg);
  }
}
#log-in-girl-7-path33_tr {
  animation: log-in-girl-7-path33_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path33_tr__tr {
  0% {
    transform: translate(665.120039px, 691.85394px) rotate(8.121512deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(665.120039px, 691.85394px) rotate(0deg);
  }
  50% {
    transform: translate(665.120039px, 691.85394px) rotate(8.121512deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(665.120039px, 691.85394px) rotate(0deg);
  }
  100% {
    transform: translate(665.120039px, 691.85394px) rotate(8.121512deg);
  }
}
#log-in-girl-7-head_tr {
  animation: log-in-girl-7-head_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-head_tr__tr {
  0% {
    transform: translate(705.871305px, 614.365228px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(705.871305px, 614.365228px) rotate(-8.588272deg);
  }
  50% {
    transform: translate(705.871305px, 614.365228px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(705.871305px, 614.365228px) rotate(-8.588272deg);
  }
  100% {
    transform: translate(705.871305px, 614.365228px) rotate(0deg);
  }
}
#log-in-girl-7-head2_tr {
  animation: log-in-girl-7-head2_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-head2_tr__tr {
  0% {
    transform: translate(702.441048px, 545.758393px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(702.441048px, 545.758393px) rotate(-5.505255deg);
  }
  50% {
    transform: translate(702.441048px, 545.758393px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(702.441048px, 545.758393px) rotate(-5.505255deg);
  }
  100% {
    transform: translate(702.441048px, 545.758393px) rotate(0deg);
  }
}
#log-in-girl-7-path44_to {
  animation: log-in-girl-7-path44_to__to 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path44_to__to {
  0% {
    transform: translate(719.807821px, 512.540409px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(726.089217px, 520.343549px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(721.195931px, 513.325343px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(726.089217px, 520.343549px);
  }
  100% {
    transform: translate(719.807821px, 512.540409px);
  }
}
#log-in-girl-7-path44_tr {
  animation: log-in-girl-7-path44_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path44_tr__tr {
  0% {
    transform: rotate(-13.563008deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: rotate(3.448383deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: rotate(-6.938377deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: rotate(6.991027deg);
  }
  100% {
    transform: rotate(-13.563008deg);
  }
}
#log-in-girl-7-hair_tk {
  animation: log-in-girl-7-hair_tk__tk 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-hair_tk__tk {
  0% {
    transform: translate(732.607645px, 458.024355px) skewX(1deg) skewY(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(732.607645px, 458.024355px) skewX(-5deg) skewY(3deg);
  }
  50% {
    transform: translate(732.607645px, 458.024355px) skewX(1deg) skewY(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(732.607645px, 458.024355px) skewX(-5deg) skewY(3deg);
  }
  100% {
    transform: translate(732.607645px, 458.024355px) skewX(1deg) skewY(0deg);
  }
}
#log-in-girl-7-hair_ts {
  animation: log-in-girl-7-hair_ts__ts 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-hair_ts__ts {
  0% {
    transform: scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: scale(1.059419, 0.901016);
  }
  50% {
    transform: scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: scale(1.059419, 0.901016);
  }
  100% {
    transform: scale(1, 1);
  }
}
#log-in-girl-7-path48 {
  animation: log-in-girl-7-path48__m 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path48__m {
  0% {
    d: path(
      "M725.170000,436.810000C709.550000,415.920000,626.260000,427.310000,616.170000,463.560000C609.760000,486.560000,604.460000,534.350000,599.350000,550.740000C594.240000,567.130000,544.940000,610.110000,554.070000,669.230000C555.794215,670.411761,577.092540,678.328207,577.218730,678.032809C603.435527,616.662099,650.316634,610.281435,656.455978,546.714785C662.289931,486.310155,660.749387,435.507762,725.170000,436.810000Z"
    );
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    d: path(
      "M725.170000,436.810000C709.550000,415.920000,645.010172,422.242070,620.019346,453.731100C601.861719,476.610138,588.920271,501.101131,588.591843,544.813177C588.263414,588.525224,576.117192,614.145694,548.435988,653.777810C549.177800,662.379359,551.583625,667.146292,554.070000,669.230000C589.821526,660.387076,650.316634,610.281435,656.455978,546.714785C662.289931,486.310155,660.749387,435.507762,725.170000,436.810000Z"
    );
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    d: path(
      "M725.170000,436.810000C709.550000,415.920000,626.260000,427.310000,616.170000,463.560000C609.760000,486.560000,604.460000,534.350000,599.350000,550.740000C594.240000,567.130000,544.940000,610.110000,554.070000,669.230000C555.794215,670.411761,577.092540,678.328207,577.218730,678.032809C603.435527,616.662099,650.316634,610.281435,656.455978,546.714785C662.289931,486.310155,660.749387,435.507762,725.170000,436.810000Z"
    );
  }
}
#log-in-girl-7-path49 {
  animation: log-in-girl-7-path49__m 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path49__m {
  0% {
    d: path(
      "M559.470000,637.750000C529.160000,584.750000,552.860144,561.983824,563.711077,548.950273C583.521966,525.154507,594.432127,515.535062,604.236448,485.449198C622.846008,428.343284,678.374497,419.545433,709.055309,433.020606L706.815309,443.020606C677.012531,420.382152,622.416425,447.949007,612.350000,484.190000C605.947726,507.239369,594.158144,525.798313,570.610000,551.710000C559.269106,564.189188,536.880000,581.520000,561.490000,635.140000Z"
    );
  }
  25% {
    d: path(
      "M547.009719,634.240356C543.702713,596.607323,581.126035,582.430222,587.985118,561.189000C599.365150,534.145871,590.152422,511.262333,604.812642,478.793232C626.059209,428.914357,677.887116,418.242156,708.647655,435.265303L706.407655,445.265303C675.384820,422.104194,625.991879,447.387062,611.873537,480.737237C599.394269,507.815132,608.917191,535.377964,595.455243,564.818231C588.131493,587.204591,554.799109,597.743653,551.462944,635.713955Z"
    );
  }
  50% {
    d: path(
      "M534.549439,630.730712C558.245426,608.464647,575.215150,588.350291,578.082385,558.901399C581.031560,528.610906,585.872717,506.989603,605.388837,472.137266C629.272411,429.485429,677.399735,416.938879,708.240000,437.510000L706,447.510000C673.757108,423.826235,629.567333,446.825117,611.397073,477.284473C592.840812,508.390894,589.499464,530.431286,586.123711,563.400134C582.817105,595.693666,572.718217,613.967305,541.435888,636.287909Z"
    );
  }
  75% {
    d: path(
      "M563.601214,639.915125C554.378843,604.301267,573.568948,580.210416,580.535152,558.025453C589.324931,530.033029,590.152422,511.262333,604.812642,478.793232C626.059209,428.914357,677.887116,418.242156,708.647655,435.265303L706.407655,445.265303C675.384820,422.104194,625.991879,447.387062,611.873537,480.737237C599.394269,507.815132,596.502514,530.417680,588.005277,561.654684C581.755969,584.627992,565.488970,597.675725,568.054438,641.388723Z"
    );
  }
  100% {
    d: path(
      "M559.470000,637.750000C529.160000,584.750000,552.860144,561.983824,563.711077,548.950273C583.521966,525.154507,594.432127,515.535062,604.236448,485.449198C622.846008,428.343284,678.374497,419.545433,709.055309,433.020606L706.815309,443.020606C677.012531,420.382152,622.416425,447.949007,612.350000,484.190000C605.947726,507.239369,594.158144,525.798313,570.610000,551.710000C559.269106,564.189188,536.880000,581.520000,561.490000,635.140000Z"
    );
  }
}
#log-in-girl-7-path49_tr {
  animation: log-in-girl-7-path49_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path49_tr__tr {
  0% {
    transform: translate(692.109626px, 433.059743px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(692.109626px, 433.059743px) rotate(5.207028deg);
  }
  50% {
    transform: translate(692.109626px, 433.059743px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(692.109626px, 433.059743px) rotate(5.207028deg);
  }
  100% {
    transform: translate(692.109626px, 433.059743px) rotate(0deg);
  }
}
#log-in-girl-7-path51 {
  animation: log-in-girl-7-path51__m 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path51__m {
  0% {
    d: path(
      "M528.590000,659.630000C538.530000,601.040000,580.740000,610.440000,593.590000,589.140000C602.400000,574.570000,596.080000,519.140000,604.180000,496.710000C626.560000,434.620000,699.580000,411.330000,725.180000,436.810000L722.940000,446.810000C708.640000,431.630000,649.320000,429.330000,615.940000,488.480000C604.280000,509.130000,619.370000,560.530000,601.190000,593C586.240000,619.710000,546.430000,603.540000,536.480000,662.130000Z"
    );
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    d: path(
      "M567.052559,670.553332C552.615952,613.464940,584.009590,597.818236,599.033627,578.577338C614.162724,560.578392,610.366316,525.596627,615.680430,490.104013C624.935683,428.288791,699.580000,411.330000,725.180000,436.810000L722.940000,446.810000C708.640000,431.630000,649.931454,425.276960,627.764279,485.030447C616.290889,515.957939,630.898690,554.052444,605.202794,584.390110C586.062318,607.652093,565.528097,622.416889,574.993608,670.077395Z"
    );
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    d: path(
      "M528.590000,659.630000C538.530000,601.040000,580.740000,610.440000,593.590000,589.140000C602.400000,574.570000,596.080000,519.140000,604.180000,496.710000C626.560000,434.620000,699.580000,411.330000,725.180000,436.810000L722.940000,446.810000C708.640000,431.630000,649.320000,429.330000,615.940000,488.480000C604.280000,509.130000,619.370000,560.530000,601.190000,593C586.240000,619.710000,546.430000,603.540000,536.480000,662.130000Z"
    );
  }
}
#log-in-girl-7-path51_tr {
  animation: log-in-girl-7-path51_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path51_tr__tr {
  0% {
    transform: translate(708.823608px, 435.898052px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(708.823608px, 435.898052px) rotate(5deg);
  }
  50% {
    transform: translate(708.823608px, 435.898052px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(708.823608px, 435.898052px) rotate(6.313811deg);
  }
  100% {
    transform: translate(708.823608px, 435.898052px) rotate(0deg);
  }
}
#log-in-girl-7-path52_tr {
  animation: log-in-girl-7-path52_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path52_tr__tr {
  0% {
    transform: translate(741.877389px, 461.448006px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    transform: translate(741.877389px, 461.448006px) rotate(-1.495972deg);
  }
  50% {
    transform: translate(741.877389px, 461.448006px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(741.877389px, 461.448006px) rotate(-1.495972deg);
  }
  100% {
    transform: translate(741.877389px, 461.448006px) rotate(0deg);
  }
}
#log-in-girl-7-belt {
  animation: log-in-girl-7-belt__m 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-belt__m {
  0% {
    d: path(
      "M776.214400,817.696761C776.214400,817.696761,779.770471,846.295504,779.770471,846.295504C741.860471,851.295504,699.314668,852.556041,669.224668,862.716041L665.796641,832.223556C700.466641,821.993556,742.434400,820.656761,776.214400,817.696761Z"
    );
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    d: path(
      "M775.622204,818.504548C775.622204,818.504548,771.687167,838.900602,771.699890,838.927585C733.789890,843.927585,699.314668,852.556041,669.224668,862.716041L665.796641,832.223556C700.466641,821.993556,748.051920,817.788298,775.622204,818.504548Z"
    );
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    d: path(
      "M776.214400,817.696761C776.214400,817.696761,779.770471,846.295504,779.770471,846.295504C741.860471,851.295504,699.314668,852.556041,669.224668,862.716041L665.796641,832.223556C700.466641,821.993556,742.434400,820.656761,776.214400,817.696761Z"
    );
  }
}
#log-in-girl-7-belt_to {
  animation: log-in-girl-7-belt_to__to 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-belt_to__to {
  0% {
    transform: translate(737.511421px, 839.512105px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(738.622712px, 835.205669px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: translate(737.511421px, 839.512105px);
  }
}
#log-in-girl-7-belt_tk {
  animation: log-in-girl-7-belt_tk__tk 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-belt_tk__tk {
  0% {
    transform: skewX(2deg) skewY(3deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: skewX(-4deg) skewY(5deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: skewX(2deg) skewY(3deg);
  }
}
#log-in-girl-7-belt_ts {
  animation: log-in-girl-7-belt_ts__ts 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-belt_ts__ts {
  0% {
    transform: scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: scale(1.025309, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
#log-in-girl-7-keyframes_to {
  animation: log-in-girl-7-keyframes_to__to 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-keyframes_to__to {
  0% {
    transform: translate(1200px, 500.000504px);
  }
  58.333333% {
    transform: translate(1200px, 500.000504px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    transform: translate(550.981957px, 489.549451px);
  }
  100% {
    transform: translate(550.981957px, 489.549451px);
  }
}
#log-in-girl-7-keyframes_ts {
  animation: log-in-girl-7-keyframes_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-keyframes_ts__ts {
  0% {
    transform: scale(1, 1);
  }
  54.166667% {
    transform: scale(1, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  58.333333% {
    transform: scale(0.534953, 0.534953);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60.833333% {
    transform: scale(0.35716, 0.35716);
  }
  75% {
    transform: scale(0.418957, 0.418957);
  }
  100% {
    transform: scale(0.418957, 0.418957);
  }
}
#log-in-girl-7-keyframe5_ts {
  animation: log-in-girl-7-keyframe5_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-keyframe5_ts__ts {
  0% {
    transform: translate(1507.409843px, 604.590103px) rotate(45deg) scale(0, 0);
  }
  41.666667% {
    transform: translate(1507.409843px, 604.590103px) rotate(45deg) scale(0, 0);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  54.166667% {
    transform: translate(1507.409843px, 604.590103px) rotate(45deg) scale(1, 1);
  }
  100% {
    transform: translate(1507.409843px, 604.590103px) rotate(45deg) scale(1, 1);
  }
}
#log-in-girl-7-rect6 {
  animation: log-in-girl-7-rect6_c_o 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-rect6_c_o {
  0% {
    opacity: 0;
  }
  54.166667% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  58.333333% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#log-in-girl-7-keyframe8_ts {
  animation: log-in-girl-7-keyframe8_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-keyframe8_ts__ts {
  0% {
    transform: translate(1200.006447px, 429.609368px) rotate(45deg) scale(0, 0);
  }
  41.666667% {
    transform: translate(1200.006447px, 429.609368px) rotate(45deg) scale(0, 0);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  54.166667% {
    transform: translate(1200.006447px, 429.609368px) rotate(45deg) scale(1, 1);
  }
  100% {
    transform: translate(1200.006447px, 429.609368px) rotate(45deg) scale(1, 1);
  }
}
#log-in-girl-7-pen_to {
  animation: log-in-girl-7-pen_to__to 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-pen_to__to {
  0% {
    transform: translate(1200px, 500px);
  }
  33.333333% {
    transform: translate(1200px, 500px);
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }
  41.666667% {
    transform: translate(1199.986164px, 480.121636px);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  54.166667% {
    transform: translate(1199.925383px, 637.212931px);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  58.333333% {
    transform: translate(1198.002233px, 690.258932px);
  }
  100% {
    transform: translate(1198.002233px, 690.258932px);
  }
}
#log-in-girl-7-pen_tr {
  animation: log-in-girl-7-pen_tr__tr 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-pen_tr__tr {
  0% {
    transform: rotate(360deg);
  }
  21.666667% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  33.333333% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
#log-in-girl-7-pen_ts {
  animation: log-in-girl-7-pen_ts__ts 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-pen_ts__ts {
  0% {
    transform: scale(0.340932, 0.340932);
  }
  21.666667% {
    transform: scale(0.340932, 0.340932);
  }
  33.333333% {
    transform: scale(1.136798, 1.136798);
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }
  41.666667% {
    transform: scale(0.893001, 0.893001);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  54.166667% {
    transform: scale(1.312147, 1.312147);
  }
  100% {
    transform: scale(1.312147, 1.312147);
  }
}
#log-in-girl-7-pen {
  animation: log-in-girl-7-pen_c_o 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-pen_c_o {
  0% {
    opacity: 1;
  }
  54.166667% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  58.333333% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#log-in-girl-7-ellipse11 {
  animation: log-in-girl-7-ellipse11_s_do 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-ellipse11_s_do {
  0% {
    stroke-dashoffset: 359.62;
  }
  21.666667% {
    stroke-dashoffset: 359.62;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  33.333333% {
    stroke-dashoffset: 719.24;
  }
  100% {
    stroke-dashoffset: 719.24;
  }
}
#log-in-girl-7-path54 {
  animation: log-in-girl-7-path54_s_do 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-path54_s_do {
  0% {
    stroke-dashoffset: 120.51;
  }
  29.166667% {
    stroke-dashoffset: 120.51;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  33.333333% {
    stroke-dashoffset: 241.02;
  }
  100% {
    stroke-dashoffset: 241.02;
  }
}
#log-in-girl-7-triangle_to {
  animation: log-in-girl-7-triangle_to__to 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-triangle_to__to {
  0% {
    transform: translate(1200px, 500px);
  }
  21.666667% {
    transform: translate(1200px, 500px);
  }
  70% {
    transform: translate(1332.262482px, 501.160233px);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  79.166667% {
    transform: translate(1200px, 500px);
  }
  100% {
    transform: translate(1200px, 500px);
  }
}
#log-in-girl-7-triangle_ts {
  animation: log-in-girl-7-triangle_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-triangle_ts__ts {
  0% {
    transform: rotate(90deg) scale(1, 1);
  }
  8.333333% {
    transform: rotate(90deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  10% {
    transform: rotate(90deg) scale(0.615783, 0.615783);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  13.333333% {
    transform: rotate(90deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.26, 0.46, 0.995, 1);
  }
  15% {
    transform: rotate(90deg) scale(0, 0);
    animation-timing-function: cubic-bezier(0, 0, 0.255, 1);
  }
  21.666667% {
    transform: rotate(90deg) scale(0, 0);
  }
  70% {
    transform: rotate(90deg) scale(0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  79.166667% {
    transform: rotate(90deg) scale(1, 1);
  }
  100% {
    transform: rotate(90deg) scale(1, 1);
  }
}
#log-in-girl-7-stroke-path_tr {
  animation: log-in-girl-7-stroke-path_tr__tr 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-stroke-path_tr__tr {
  0% {
    transform: translate(1200px, 500px) rotate(270deg);
  }
  15% {
    transform: translate(1200px, 500px) rotate(270deg);
    animation-timing-function: cubic-bezier(0.99, -0.105, 0.58, 1);
  }
  21.666667% {
    transform: translate(1200px, 500px) rotate(-90deg);
    animation-timing-function: cubic-bezier(0.44, 0.015, 0.46, 0.99);
  }
  33.333333% {
    transform: translate(1200px, 500px) rotate(-450deg);
  }
  100% {
    transform: translate(1200px, 500px) rotate(-450deg);
  }
}
#log-in-girl-7-stroke-path_ts {
  animation: log-in-girl-7-stroke-path_ts__ts 12000ms linear infinite normal
    forwards;
}
@keyframes log-in-girl-7-stroke-path_ts__ts {
  0% {
    transform: scale(2.07539, 2.07539);
  }
  13.333333% {
    transform: scale(2.07539, 2.07539);
    animation-timing-function: cubic-bezier(0.26, 0.46, 0.995, 1);
  }
  15% {
    transform: scale(0.1, 0.1);
    animation-timing-function: cubic-bezier(0, 0, 0.255, 1);
  }
  16.666667% {
    transform: scale(1, 1);
  }
  21.666667% {
    transform: scale(1, 1);
    animation-timing-function: cubic-bezier(0.44, 0.015, 0.46, 0.99);
  }
  33.333333% {
    transform: scale(2.07539, 2.07539);
  }
  100% {
    transform: scale(2.07539, 2.07539);
  }
}
#log-in-girl-7-stroke-path {
  animation-name: log-in-girl-7-stroke-path_s_w, log-in-girl-7-stroke-path_s_da;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-stroke-path_s_w {
  0% {
    stroke-width: 4;
  }
  13.333333% {
    stroke-width: 4;
    animation-timing-function: cubic-bezier(0.26, 0.46, 0.995, 1);
  }
  15% {
    stroke-width: 110;
    animation-timing-function: cubic-bezier(0, 0, 0.255, 1);
  }
  21.666667% {
    stroke-width: 110;
    animation-timing-function: cubic-bezier(0, 0, 0.255, 1);
  }
  33.333333% {
    stroke-width: 4;
  }
  100% {
    stroke-width: 4;
  }
}
@keyframes log-in-girl-7-stroke-path_s_da {
  0% {
    stroke-dasharray: 345.58, 287.9833;
  }
  15% {
    stroke-dasharray: 345.58, 287.9833;
    animation-timing-function: cubic-bezier(0, 0, 0.255, 1);
  }
  16.666667% {
    stroke-dasharray: 57.5966, 287.9833;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  18.333333% {
    stroke-dasharray: 57.5966, 287.9833;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  20% {
    stroke-dasharray: 110, 287.9833;
  }
  21.666667% {
    stroke-dasharray: 57.5966, 287.9833;
    animation-timing-function: cubic-bezier(0.44, 0.015, 0.46, 0.99);
  }
  33.333333% {
    stroke-dasharray: 345.58, 287.9833;
  }
  100% {
    stroke-dasharray: 345.58, 287.9833;
  }
}
#log-in-girl-7-stroke2 {
  animation: log-in-girl-7-stroke2_c_o 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-stroke2_c_o {
  0% {
    opacity: 1;
  }
  6.666667% {
    opacity: 1;
  }
  7.166667% {
    opacity: 0;
  }
  9.166667% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  11.750000% {
    opacity: 1;
  }
  11.916667% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#log-in-girl-7-path55 {
  animation-name: log-in-girl-7-path55_s_w, log-in-girl-7-path55_s_do;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path55_s_w {
  0% {
    stroke-width: 12;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  4.166667% {
    stroke-width: 15;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  7.500000% {
    stroke-width: 10;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  10.833333% {
    stroke-width: 15;
  }
  18.333333% {
    stroke-width: 12;
  }
  100% {
    stroke-width: 12;
  }
}
@keyframes log-in-girl-7-path55_s_do {
  0% {
    stroke-dashoffset: 200;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  18.333333% {
    stroke-dashoffset: 4063;
  }
  100% {
    stroke-dashoffset: 4063;
  }
}
#log-in-girl-7-path56 {
  animation-name: log-in-girl-7-path56_s_w, log-in-girl-7-path56_s_do;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path56_s_w {
  0% {
    stroke-width: 12;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  4.166667% {
    stroke-width: 15;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  7.500000% {
    stroke-width: 10;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  10.833333% {
    stroke-width: 15;
  }
  18.333333% {
    stroke-width: 12;
  }
  100% {
    stroke-width: 12;
  }
}
@keyframes log-in-girl-7-path56_s_do {
  0% {
    stroke-dashoffset: 80;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  17.500000% {
    stroke-dashoffset: 3823;
  }
  100% {
    stroke-dashoffset: 3823;
  }
}
#log-in-girl-7-path57 {
  animation-name: log-in-girl-7-path57_s_w, log-in-girl-7-path57_s_do,
    log-in-girl-7-path57_s_da;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path57_s_w {
  0% {
    stroke-width: 6;
  }
  26.666667% {
    stroke-width: 6;
  }
  30.833333% {
    stroke-width: 12;
  }
  35% {
    stroke-width: 6;
  }
  100% {
    stroke-width: 6;
  }
}
@keyframes log-in-girl-7-path57_s_do {
  0% {
    stroke-dashoffset: 2843;
  }
  25% {
    stroke-dashoffset: 2843;
  }
  32.500000% {
    stroke-dashoffset: 1483.76;
  }
  38.333333% {
    stroke-dashoffset: 104;
  }
  100% {
    stroke-dashoffset: 104;
  }
}
@keyframes log-in-girl-7-path57_s_da {
  0% {
    stroke-dasharray: 120, 2603;
  }
  25% {
    stroke-dasharray: 120, 2603;
  }
  32.500000% {
    stroke-dasharray: 400, 2603;
  }
  39.166667% {
    stroke-dasharray: 104, 2603;
  }
  100% {
    stroke-dasharray: 104, 2603;
  }
}
#log-in-girl-7-path58 {
  animation-name: log-in-girl-7-path58_s_w, log-in-girl-7-path58_s_do,
    log-in-girl-7-path58_s_da;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path58_s_w {
  0% {
    stroke-width: 6;
  }
  26.666667% {
    stroke-width: 6;
  }
  30.833333% {
    stroke-width: 12;
  }
  35% {
    stroke-width: 6;
  }
  100% {
    stroke-width: 6;
  }
}
@keyframes log-in-girl-7-path58_s_do {
  0% {
    stroke-dashoffset: 2663;
  }
  25% {
    stroke-dashoffset: 2663;
  }
  37.250000% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 30;
  }
}
@keyframes log-in-girl-7-path58_s_da {
  0% {
    stroke-dasharray: 30, 2603;
  }
  25% {
    stroke-dasharray: 30, 2603;
  }
  32.500000% {
    stroke-dasharray: 200, 2603;
  }
  37.916667% {
    stroke-dasharray: 30, 2603;
  }
  100% {
    stroke-dasharray: 30, 2603;
  }
}
#log-in-girl-7-stroke-32 {
  animation: log-in-girl-7-stroke-32_c_o 12000ms linear infinite normal forwards;
}
@keyframes log-in-girl-7-stroke-32_c_o {
  0% {
    opacity: 1;
  }
  56.250000% {
    opacity: 1;
  }
  56.416667% {
    opacity: 0;
  }
  60.750000% {
    opacity: 0;
  }
  61.083333% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#log-in-girl-7-path59 {
  animation-name: log-in-girl-7-path59_s_w, log-in-girl-7-path59_s_do;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path59_s_w {
  0% {
    stroke-width: 12;
  }
  50% {
    stroke-width: 12;
  }
  57.500000% {
    stroke-width: 6;
  }
  61.666667% {
    stroke-width: 12;
  }
  100% {
    stroke-width: 12;
  }
}
@keyframes log-in-girl-7-path59_s_do {
  0% {
    stroke-dashoffset: 5972;
  }
  50% {
    stroke-dashoffset: 5972;
  }
  66.666667% {
    stroke-dashoffset: 600;
  }
  100% {
    stroke-dashoffset: 600;
  }
}
#log-in-girl-7-path60 {
  animation-name: log-in-girl-7-path60_s_w, log-in-girl-7-path60_s_do;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path60_s_w {
  0% {
    stroke-width: 12;
  }
  50% {
    stroke-width: 12;
  }
  57.500000% {
    stroke-width: 6;
  }
  61.666667% {
    stroke-width: 12;
  }
  100% {
    stroke-width: 12;
  }
}
@keyframes log-in-girl-7-path60_s_do {
  0% {
    stroke-dashoffset: 5172;
  }
  49.916667% {
    stroke-dashoffset: 5172;
  }
  65.250000% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 200;
  }
}
#log-in-girl-7-path61 {
  animation-name: log-in-girl-7-path61_s_w, log-in-girl-7-path61_s_do,
    log-in-girl-7-path61_s_da;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path61_s_w {
  0% {
    stroke-width: 6;
  }
  76.666667% {
    stroke-width: 6;
  }
  80% {
    stroke-width: 12;
  }
  85% {
    stroke-width: 6;
  }
  100% {
    stroke-width: 6;
  }
}
@keyframes log-in-girl-7-path61_s_do {
  0% {
    stroke-dashoffset: 2843;
  }
  75% {
    stroke-dashoffset: 2843;
  }
  82.500000% {
    stroke-dashoffset: 1483.76;
  }
  88.333333% {
    stroke-dashoffset: 104;
  }
  100% {
    stroke-dashoffset: 104;
  }
}
@keyframes log-in-girl-7-path61_s_da {
  0% {
    stroke-dasharray: 120, 2603;
  }
  75% {
    stroke-dasharray: 120, 2603;
  }
  82.500000% {
    stroke-dasharray: 400, 2603;
  }
  89.166667% {
    stroke-dasharray: 104, 2603;
  }
  100% {
    stroke-dasharray: 104, 2603;
  }
}
#log-in-girl-7-path62 {
  animation-name: log-in-girl-7-path62_s_w, log-in-girl-7-path62_s_do,
    log-in-girl-7-path62_s_da;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes log-in-girl-7-path62_s_w {
  0% {
    stroke-width: 6;
  }
  76.666667% {
    stroke-width: 6;
  }
  80% {
    stroke-width: 12;
  }
  85% {
    stroke-width: 6;
  }
  100% {
    stroke-width: 6;
  }
}
@keyframes log-in-girl-7-path62_s_do {
  0% {
    stroke-dashoffset: 2663;
  }
  75% {
    stroke-dashoffset: 2663;
  }
  87.250000% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 30;
  }
}
@keyframes log-in-girl-7-path62_s_da {
  0% {
    stroke-dasharray: 30, 2603;
  }
  75% {
    stroke-dasharray: 30, 2603;
  }
  82.500000% {
    stroke-dasharray: 200, 2603;
  }
  87.916667% {
    stroke-dasharray: 30, 2603;
  }
  100% {
    stroke-dasharray: 30, 2603;
  }
}
</style>
