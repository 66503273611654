<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>
      <div
        class="flex flex-col items-center justify-start w-full text-yellow-50 p-3"
      >
        <div class="py-1 md:py-8 lg:py-12 max-w-2xl w-full">
          <div class="p-4 mb-3 space-y-3">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
        </div>
        <div class="flex-glow">
          <logingirl class="h-96" />
        </div>
        <span
          class="font-extrabold text-7xl tracking-tight text-center mb-6 -m-36 text-shadow-white"
          >404</span
        >
        <span class="font-extrabold text-5xl text-shadow-white"
          >Page not found</span
        >
        <!-- <footer>
          <StickFooter />
        </footer> -->
      </div>
    </div>
  </div>
</template>

<script>
import logingirl from "@/assets/svg/logingirl.vue";
import ConnectWallet from "@/components/ConnectWallet.vue";

export default {
  components: {
    logingirl,
    ConnectWallet,
  },
};
</script>
